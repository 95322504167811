import { GET_INFRASTRUCTURE_VERSION } from 'actions/types';
import { storage, db } from 'config/firebase';
const QRCode = require('qrcode');
export const generateIdBooking = (parcPrefix) => {
  var length = 7;
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `TB-${parcPrefix}-${result}`;
};
// export const forbiddenNumSerieValidator = (value) => {
//   if ((size && size == 48) || (size && size == 53) || (size && size == 60)) {
//     const regex = new RegExp('^[A-Za-z]{4}\\d{6,7}$');

//     if (!regex.test(value)) {
//       return false;
//     }
//     if (value.length < 10) {
//       return false;
//     }
//     return true;
//   } else {
//     const nameRe = new RegExp('^[A-Za-z]{4}\\d{6,7}$');
//     const corresp = {
//       A: 10,
//       B: 12,
//       C: 13,
//       D: 14,
//       E: 15,
//       F: 16,
//       G: 17,
//       H: 18,
//       I: 19,
//       J: 20,
//       K: 21,
//       L: 23,
//       M: 24,
//       N: 25,
//       O: 26,
//       P: 27,
//       Q: 28,
//       R: 29,
//       S: 30,
//       T: 31,
//       U: 32,
//       V: 34,
//       W: 35,
//       X: 36,
//       Y: 37,
//       Z: 38
//     };
//     const numSerie = value.toUpperCase();
//     let result = 0;
//     if (nameRe.test(numSerie)) {
//       for (let i = 0; i < numSerie.length - 1; i++) {
//         if (i < 4) {
//           result += corresp[numSerie[i]] * Math.pow(2, i);
//         } else {
//           result += parseInt(numSerie[i]) * Math.pow(2, i);
//         }
//       }
//     } else {
//       return false;
//     }
//     const finalResult = result - ~~(result / 11) * 11 === 10 ? 0 : result - ~~(result / 11) * 11;
//     return finalResult === parseInt(numSerie[numSerie.length - 1]);
//   }
// };

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
};
export const getEirInStorage = async (data) => {
  const { parc, numSerie, eirId } = data;
  return await storage
    .ref(`${parc}/interchanges/${numSerie}/${eirId}/eir-${numSerie}.pdf`)
    .getDownloadURL();
};

export const divideWidthPerGate = (width) => {
  let newWidth;
  newWidth = width.replace('%', '');

  return `${newWidth - 1}%`;
};

export const generateQRCode = (data) => {
  const image = new Image();
  let url = '';
  // Converting the data into String format
  let stringdata = JSON.stringify(data);

  // Print the QR code to terminal
  // eslint-disable-next-line
  QRCode.toString(stringdata, { type: 'terminal' }, function (err, QRcode) {
    if (err) return console.log('error occurred');
  });

  // Converting the data into base64
  QRCode.toDataURL(stringdata, function (err, code) {
    if (err) return console.log('error occurred');
    url = code;
  });

  image.src = url;
  return image;
};
export const forbiddenNumChassisValidator = (serialNumber) => {
  const regex = new RegExp('^[A-Za-z]{4}\\d{5,7}$');
  if (!regex.test(serialNumber)) {
    return true;
  }
  if (!["Z", "A", "C", "V", "T", "S", "I", "E", "P"].includes(serialNumber.charAt(3).toUpperCase())) {
    return true;
  } else if (serialNumber.length < 9) {
    return true;
  }
  return false;
};

export const getVBSVersion = (target) => async (dispatch) => {
  try {
    let currentVersion = '';
    const version = await db.collection('constants').doc('Version').get();
    const { VBS } = version.data();
    if (target.includes('staging')) {
      currentVersion = VBS.staging;
    } else {
      currentVersion = VBS.prod;
    }
    dispatch({
      type: GET_INFRASTRUCTURE_VERSION,
      payload: currentVersion
    });
  } catch (error) {
    console.log(error);
  }
};
