// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Chip,
  FormHelperText,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { columns } from "./tableHeader";
import { DataGrid, frFR, enUS } from "@mui/x-data-grid";
import { connect } from "react-redux";

import CustomDialog from "components/CustomComponent/CustomDialog/CustomDialog";
import {
  getVbsYards,
  getTransportersByCountry,
  addTransporter,
  getTransporterVBS,
} from "actions/yard";
import { createUser } from "actions/users";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getUsersPerYard, getUsersPerTruckingCompany } from "actions/users";
import LockResetIcon from "@mui/icons-material/LockReset";
import { resetUserPassword } from "actions/users";
import Tooltip from "@mui/material/Tooltip";
import { sendWelcomeMail } from "actions/users";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import { disableUser } from "actions/users";
import i18next from "i18next";
import { columnsEn } from "./tableHeaderEN";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { editUser } from "actions/users";
import { emailValidation } from "helpers/helpers";
const Iconstyle = {
  width: "50px",
  color: "#1A73E8",
};

const Users = ({
  t,
  getTransporterVBS,
  allTransportersVbs,
  disableUser,
  usersPerCompany,

  createUser,
  getUsersPerTruckingCompany,
  editUser,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [isEditing, setIsEditing] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [parcNames, setParcNames] = useState([]);
  const [selectedTransporter, setSelectedTransporter] = useState({});
  const [formData, setFormData] = useState({
    createdAt: Date.now(),
    userEmail: '',
    userPassword: '',
    userName: '',
    userPhoneNumber: '',
    selectedTrucker: '',
    userId: ''
  });
  const [yards, setYards] = useState([]);

  const { userEmail, userPassword, userName, userPhoneNumber } = formData;
  const [formValidator, setFormValidator] = useState({
    emailValidator: false,
    passwordValidator: false,
    usernameValidator: false
  });
  const { emailValidator, passwordValidator, usernameValidator } = formValidator;
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    getTransporterVBS();
  }, []);
  useEffect(() => {
    if (
      allTransportersVbs &&
      allTransportersVbs.length > 0 &&
      allTransportersVbs[0].companyName !== selectedTransporter
    ) {
      setSelectedTransporter(allTransportersVbs[0]);
      setYards(allTransportersVbs[0].yard);
      getUsersPerTruckingCompany(allTransportersVbs[0].companyName);
    }
  }, [allTransportersVbs]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'selectedTransporter') {
      setSelectedTransporter(value);
      setYards(value.yard);

      getUsersPerTruckingCompany(value.companyName);
    }
    if (name === 'userEmail') {
      setFormValidator({ ...formValidator, emailValidator: !emailValidation(value) });
    }
    if (name === 'userName' && value.length < 2) {
      setFormValidator({ ...formValidator, usernameValidator: true });
    }
    if (name === 'userName' && value.length >= 2) {
      setFormValidator({ ...formValidator, usernameValidator: false });
    }
    if (name === 'userPassword' && value.length < 6) {
      setFormValidator({ ...formValidator, passwordValidator: true });
    }
    if (name === 'userPassword' && value.length >= 6) {
      setFormValidator({ ...formValidator, passwordValidator: false });
    }
    if (name === 'parcNames') {
      setParcNames(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    setIsVisible(false);
    reinitForm();
  };

  const handleNumberChange = (event) => {
    setFormData({ ...formData, userPhoneNumber: `+${event}` });
  };

  const reinitForm = () => {
    setFormData({
      createdAt: Date.now(),
      userEmail: '',
      userPassword: '',
      userName: '',
      userPhoneNumber: '',
      userId: ''
    });
    setFormValidator({ emailValidator: false, passwordValidator: false, usernameValidator: false });
    setParcNames([]);
  };

  const handleSubmit = () => {
    if (!emailValidator && formData.userPassword && userName && parcNames.length > 0) {
      createUser(formData, selectedTransporter, parcNames);
      reinitForm();
      setIsVisible(false);
    }
  };
  const refillForm = (selectedRow) => {
    setIsEditing(true);
    setFormData({
      userEmail: selectedRow.email,
      userName: selectedRow.displayName,
      userPhoneNumber: selectedRow.phoneNumber,
      selectedTrucker: selectedRow.companyName
    });
    setParcNames(selectedRow.associatedDepot);
    setIsVisible(true);
  };
  const handleEdit = () => {
    let data = { associatedDepot: [], displayName: '', phoneNumber: '', email: '' };
    let yardsToUpdate = yards.filter((data) => !parcNames.includes(data));
    data.associatedDepot = yards.filter((data) => parcNames.includes(data));
    data.displayName = formData.userName;
    data.phoneNumber = formData.userPhoneNumber;
    data.email = formData.userEmail;
    data.companyName = selectedTransporter.companyName;
    editUser(data, yardsToUpdate);
    setIsEditing(false);
    setIsVisible(false);
    reinitForm();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid xs={4} item>
          <Button variant="contained" onClick={() => setIsVisible(true)} style={{ color: 'white' }}>
            {t('addUser')}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <FormControl fullWidth>
            <InputLabel>{t('trucker_company')}</InputLabel>
            <Select
              value={selectedTransporter}
              label={t('trucker_company')}
              name="selectedTransporter"
              style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
              onChange={(e) => handleChange(e)}>
              {allTransportersVbs &&
                allTransportersVbs.length > 0 &&
                allTransportersVbs.map((trans) => (
                  <MenuItem value={trans} key={trans.companyName}>
                    {trans.companyName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {t('userPerCompany')}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div style={{ height: 700, width: '100%' }}>
                  <DataGrid
                    localeText={
                      ({
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} ${i18next.language === 'fr' ? 'sur' : 'of'} ${count}`,
                          labelRowsPerPage:
                            i18next.language === 'fr' ? 'Lignes par page' : 'Rows per page'
                        }
                      },
                      i18next.language === 'fr'
                        ? frFR.components.MuiDataGrid.defaultProps.localeText
                        : enUS.components.MuiDataGrid.defaultProps.localeText)
                    }
                    rows={usersPerCompany || []}
                    columns={
                      i18next.language === 'fr'
                        ? [
                            ...columns,
                            {
                              field: 'actions',
                              headerName: 'Actions',
                              width: 220,
                              renderCell: (params) => (
                                <>
                                  {!params.row.disabled ? (
                                    <Tooltip title="Edit">
                                      <EditIcon
                                        fontSize="medium"
                                        style={Iconstyle}
                                        onClick={() => {
                                          refillForm(params.row);
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                  {!params.row.disabled ? (
                                    <Tooltip title="Reset password">
                                      <LockResetIcon
                                        fontSize="medium"
                                        onClick={async () => {
                                          await resetUserPassword(params.row.email);
                                        }}
                                        style={Iconstyle}
                                      />
                                    </Tooltip>
                                  ) : null}
                                  {!params.row.disabled ? (
                                    <Tooltip title="Welcome mail">
                                      <AlternateEmailIcon
                                        fontSize="medium"
                                        style={Iconstyle}
                                        onClick={async () => {
                                          await sendWelcomeMail(
                                            params.row.email,
                                            params.row.displayName
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}

                                  <Tooltip
                                    title={!params.row.disabled ? 'Disable user' : 'Enable user'}>
                                    <GroupRemoveIcon
                                      fontSize="medium"
                                      style={Iconstyle}
                                      onClick={async () => {
                                        await disableUser(params.row.email, params.row.disabled);
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )
                            }
                          ]
                        : [
                            ...columnsEn,
                            {
                              field: 'actions',
                              headerName: 'Actions',
                              width: 220,
                              renderCell: (params) => (
                                <>
                                  {!params.row.disabled ? (
                                    <Tooltip title="Edit">
                                      <EditIcon
                                        fontSize="medium"
                                        style={Iconstyle}
                                        onClick={() => {
                                          refillForm(params.row);
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                  {!params.row.disabled ? (
                                    <Tooltip title="Reset password">
                                      <LockResetIcon
                                        fontSize="medium"
                                        onClick={async () => {
                                          await resetUserPassword(params.row.email);
                                        }}
                                        style={Iconstyle}
                                      />
                                    </Tooltip>
                                  ) : null}
                                  {!params.row.disabled ? (
                                    <Tooltip title="Welcome mail">
                                      <AlternateEmailIcon
                                        fontSize="medium"
                                        style={Iconstyle}
                                        onClick={async () => {
                                          await sendWelcomeMail(
                                            params.row.email,
                                            params.row.displayName
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}

                                  <Tooltip
                                    title={!params.row.disabled ? 'Disable user' : 'Enable user'}>
                                    <GroupRemoveIcon
                                      fontSize="medium"
                                      style={Iconstyle}
                                      onClick={async () => {
                                        await disableUser(params.row.email, params.row.disabled);
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )
                            }
                          ]
                    }
                    getRowId={(row) => row.email}
                    rowsPerPageOptions={[10, 20, 50]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                  />
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomDialog
        isVisible={isVisible}
        title={`${t('addUser')} :`}
        paragraph={
          <>
            <Grid container>
              <Grid sm={6} p={2} item>
                <TextField
                  type="email"
                  id="outlined-basic0"
                  label={'Email'}
                  name="userEmail"
                  variant="outlined"
                  value={userEmail}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  disabled={isEditing}
                  error={emailValidator}
                />
                {emailValidator ? <FormHelperText>{t('emailNotValid')}</FormHelperText> : null}
              </Grid>
              {!isEditing ? (
                <Grid sm={6} p={2} item>
                  <TextField
                    id="outlined-basic1"
                    label={'Password'}
                    name="userPassword"
                    variant="outlined"
                    type="password"
                    value={userPassword}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={passwordValidator}
                  />
                  {passwordValidator ? <FormHelperText>{t('minChar6')}</FormHelperText> : null}
                </Grid>
              ) : null}

              <Grid sm={6} p={2} item>
                <TextField
                  id="outlined-basic2"
                  label={t('username')}
                  name="userName"
                  variant="outlined"
                  value={userName}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  error={usernameValidator}
                />
                {usernameValidator ? <FormHelperText>{t('minChar2')}</FormHelperText> : null}
              </Grid>

              <Grid sm={6} p={2} item>
                <ReactPhoneInput
                  country={'fr'}
                  value={userPhoneNumber}
                  onChange={handleNumberChange}
                  inputStyle={{ width: '100%', margin: '10px', height: '44.13px' }}
                />
              </Grid>

              <Grid sm={12} p={2} item>
                <InputLabel>{t('associatedTrucker')}</InputLabel>
                <TextField
                  id="outlined-basic3"
                  name="selectedTrucker"
                  variant="outlined"
                  value={selectedTransporter.companyName}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid sm={12} p={2} item>
                <InputLabel style={{ paddingBottom: '10px' }}>{t('associatedDepots')}</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  name="parcNames"
                  multiple
                  value={parcNames}
                  style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(
                        (value) => yards.includes(value) && <Chip key={value} label={value} />
                      )}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 350
                      }
                    }
                  }}
                  fullWidth>
                  {yards.map((parc) => (
                    <MenuItem key={parc} value={parc}>
                      {parc}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </>
        }
        handleClose={handleClose}
        handleSubmit={!isEditing ? handleSubmit : handleEdit}
      />
      <Footer />
    </DashboardLayout>
  );
};
const mapStateToProps = (state) => ({
  loading: state.vbs.loading,
  vbsYards: state.yard.vbsYards,
  transporters: state.yard.transporters,
  allTransportersVbs: state.yard.allTransportersVbs,
  yardsPerTransporter: state.yard.yardsPerTransporter,
  usersPerCompany: state.users.usersPerCompany,
});

export default connect(mapStateToProps, {
  getVbsYards,
  getTransporterVBS,
  addTransporter,
  getTransportersByCountry,
  getUsersPerYard,
  createUser,
  getUsersPerTruckingCompany,
  disableUser,
  editUser,
})(withTranslation()(Users));