// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";


// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { withTranslation } from "react-i18next";

function Footer({ light, t }) {
  const { size } = typography;

  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={light ? "white" : "text"}
          fontSize={size.sm}
        >
          Shipzzer &copy; {new Date().getFullYear()}, {t("All rights reserved Copyright")}
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default withTranslation()(Footer);
