import { useState, useEffect } from "react";
import "./app.css";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes, { adminRoutes } from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/shipzzerTransparant.png";
import brandDark from "assets/images/ShipzzerLogo.png";
import { loadUser } from "actions/auth";
import { withTranslation } from "react-i18next";
import { Provider } from "react-redux";
import SignIn from "layouts/authentication/sign-in";
import PrivateRoute from "PrivateRoute";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/store";
import { getVBSVersion } from 'helpers/helpers';
import { URL } from './config/config';
export const store = configureStore();
const App = () => {
  toast.configure();
  useEffect(() => {
    if (!localStorage.getItem('prefLanguage')) {
      localStorage.setItem('prefLanguage', 'en');
    }
  }, []);
  const loading = store.getState().auth.loading;

  const loggedUser = store.getState().auth.loggedUser;
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const getVersion = async () => {
    store.dispatch(await getVBSVersion(URL.API_URL));
  };
  useEffect(() => {
    getVersion();
  }, []);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route === '/authentication/sign-in') {
        return <Route path="/authentication/sign-in" element={<SignIn />} key={'sign-in'} />;
      } else if (route.route !== '/authentication/sign-in') {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <Provider store={store}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Shipzzer Trucker"
              routes={
                !loading &&
                loggedUser &&
                loggedUser.roles.length > 0 &&
                loggedUser.roles.includes('adminVBS')
                  ? adminRoutes
                  : routes
              }
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === 'vr' && <Configurator />}

        <Routes>
          {!loading &&
          loggedUser &&
          loggedUser.roles.length > 0 &&
          loggedUser.roles.includes('adminVBS')
            ? getRoutes(adminRoutes)
            : getRoutes(routes)}
          <Route
            exact
            path="*"
            element={
              !loading &&
              loggedUser &&
              loggedUser.roles.length > 0 &&
              loggedUser.roles.includes('adminVBS') ? (
                <Navigate to="/truck-companies" />
              ) : !loading &&
                loggedUser.roles.length > 0 &&
                loggedUser.roles[0] === 'managerVBS' ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/authentication/sign-in" />
              )
            }
          />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
};
export default withTranslation()(App);
