import { db, getHeaders } from "../config/firebase";
import {
  CREATE_USER,
  GET_USERS_PER_YARD,
  GET_USERS_PER_COMPANYNAME,
  DISABLE_ENABLE_USER,
  EDIT_USER,
} from "./types";
import { toast } from "react-toastify";
import axios from "axios";
import { URL } from "../config/config";
import i18next from "i18next";

export const createUser = (formData, transporter, parcNames) => async (dispatch) => {
  try {
    const data = {
      associatedDepot: parcNames,
      authorizedReports: ["No reports"],
      companyName: transporter.companyName,
      disabled: false,
      displayName: formData.userName,
      email: formData.userEmail,
      phoneNumber: formData.userPhoneNumber,
      roles: ["managerVBS"],
      createdAt: formData.createdAt,
    };
    const headers = await getHeaders();
    await axios.post(
      `${URL.API_URL}/createUserVBS`,
      { formData, transporter, parcNames },
      { headers: headers }
    );
    dispatch({
      type: CREATE_USER,
      payload: data,
    });

    toast.success(i18next.language === "fr" ? "Utilisateur créé avec succès" : "User created");
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const resetUserPassword = async (userEmail) => {
  try {
    const headers = await getHeaders();
    await axios.post(`${URL.API_URL}/resetPassword`, { email: userEmail }, { headers: headers });
    toast.success(i18next.language === "fr" ? "Reset password mail envoyé" : "Reset password send");
  } catch (error) {
    toast.error("Merci de contacter l'équipe dev");
    console.log(error);
  }
};
export const sendWelcomeMail = async (userEmail, name) => {
  try {
    const headers = await getHeaders();
    await axios.post(
      `${URL.API_URL}/welcomeMail`,
      { userType: "vbs", userEmail, name },
      {
        headers: headers,
      }
    );
    toast.success(i18next.language === "fr" ? "Welcome mail envoyé" : "Welcome mail send");
  } catch (error) {
    toast.error(
      i18next.language === "fr" ? "Merci de contacter l'équipe dev" : "Please contact dev team"
    );

    console.log(error);
  }
};
export const disableUser = (email, isDisabled) => async (dispatch) => {
  try {
    let dispatchedPayload = {};
    const headers = await getHeaders();
    const user = await db.collection("users").where("email", "==", email).get();
    dispatchedPayload = {
      ...user.docs[0].data(),
      disabled: !isDisabled,
    };
    await axios.post(
      `${URL.API_URL}/updateUser`,
      { uid: user.docs[0].id, disabled: isDisabled },
      { headers: headers }
    );
    dispatch({
      type: DISABLE_ENABLE_USER,
      payload: dispatchedPayload,
    });
    toast.success(isDisabled ? "Utilisateur connecté " : "Utilisateur déconnecté");
  } catch (error) {
    toast.error(
      i18next.language === "fr" ? "Merci de contacter l'équipe dev" : "Please contact dev team"
    );

    console.log(error);
  }
};
export const getUsersPerYard = (yard) => async (dispatch) => {
  let users = [];
  const usersResponse = await db
    .collection("users")
    .where("associatedDepot", "array-contains", yard)
    .get();
  usersResponse.forEach((doc) => {
    users.push(doc.data());
  });
  dispatch({
    type: GET_USERS_PER_YARD,
    payload: users,
  });
};
export const getUsersPerTruckingCompany = (companyName) => async (dispatch) => {
  try {
    let usersArray = [];
    const users = await db.collection("users").where("companyName", "==", companyName).get();
    users.forEach((doc) => {
      usersArray.push(doc.data());
    });
    dispatch({
      type: GET_USERS_PER_COMPANYNAME,
      payload: usersArray,
    });
  } catch (error) {
    console.log(error);
  }
};

export const editUser = (data, yardsToUpdate) => async (dispatch) => {
  try {
    const batch = db.batch();
    const user = (await db.collection("users").where("email", "==", data.email).get()).docs[0].ref;
    let promises = [];
    yardsToUpdate.map(async (depot) => {
      const company = await db
        .collection("yards")
        .doc(depot)
        .collection("fournisseurs")
        .where("companyName", "==", data.companyName)
        .get();
      const { managerVbs } = company.docs[0].data();
      if (managerVbs.includes(data.email)) {
        const batch1 = db.batch();
        batch1.update(company.docs[0].ref, {
          managerVbs: managerVbs.filter((manager) => manager !== data.email),
        });
        promises.push(batch1.commit());
      }
    });
    data.associatedDepot.map(async (depot) => {
      const company = await db
        .collection("yards")
        .doc(depot)
        .collection("fournisseurs")
        .where("companyName", "==", data.companyName)
        .get();

      const { managerVbs } = company.docs[0].data();

      if (!managerVbs.includes(data.email)) {
        const batch1 = db.batch();
        batch1.update(company.docs[0].ref, { managerVbs: [...managerVbs, data.email] });
        promises.push(batch1.commit());
      }
    });
    Promise.all(promises);
    delete data.companyName;
    batch.update(user, data);
    batch.commit();

    dispatch({
      type: EDIT_USER,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};