import { toast } from "react-toastify";
import { db } from "../config/firebase";
import {
  ADD_TRANSPORTER,
  GET_ALL_YARDS,
  GET_TRANSPORTERS,
  GET_VBS_YARDS,
  GET_TRANSPORTERS_VBS,
} from "./types";

export const getAllYards = () => async (dispatch) => {
  let allYards = [];
  const yardResponse = await db.collection("yards").get();
  yardResponse.forEach((doc) => {
    // const { nom } = doc.data();
    allYards.push(doc.id);
  });
  dispatch({
    type: GET_ALL_YARDS,
    payload: allYards,
  });
};
export const getVbsYards = () => async (dispatch) => {
  let vbsYards = [];
  const filteredYards = await db.collection("yards").where("VBS", "==", true).get();
  filteredYards.forEach((doc) => {
    vbsYards.push(doc.id);
  });

  //vbsYards =  getAllYardsVBS()

  dispatch({
    type: GET_VBS_YARDS,
    payload: vbsYards,
  });
};
const transporterVBSYard = async (yardNames) => {
  const response = await Promise.all(
    yardNames.map(async (name) => {
      const res = await db
        .collection('yards')
        .doc(name)
        .collection('fournisseurs')
        .where('activity', '==', 'Transporter')
        .get();

      return res.docs.map((doc) => {
        return {
          ...doc.data(),
          yard: [name],
          actifVBSParcs: doc.data().managerVbs && doc.data().managerVbs.length > 0 ? [name] : []
        };
      });
    })
  );
  return response.flat();
};

const unique = (array) => {
  const unique = array.filter((trans, index, self) => {
    if (index !== self.findIndex((or) => or.companyName === trans.companyName)) {
      array[self.findIndex((or) => or.companyName === trans.companyName)].yard.push(trans.yard[0]);
      array[self.findIndex((or) => or.companyName === trans.companyName)].actifVBSParcs?.push(
        ...trans.actifVBSParcs
      );
    }
    return index === self.findIndex((or) => or.companyName === trans.companyName);
  });

  return unique;
};
export const getTransportersByCountry = () => async (dispatch) => {
  let yardNames = [];

  const yards = await db.collection('yards').where('VBS', '==', true).get();

  yards.docs.map(async (doc) => {
    const { nom } = doc.data();
    return yardNames.push(nom);
  });
  const transporterArray = await transporterVBSYard(yardNames);
  const uniques = unique(transporterArray);
  dispatch({
    type: GET_TRANSPORTERS,
    payload: uniques
  });
};
export const addTransporter = (yards, data) => async (dispatch) => {
  try {
    const currentYards = data.yard;
    yards.forEach(async (yard) => {
      delete data.yard;
      await db
        .collection("yards")
        .doc(yard)
        .collection("fournisseurs")
        .doc(data.companyName)
        .set(data);
    });
    dispatch({
      type: ADD_TRANSPORTER,
      payload: { companyName: data.companyName, yards: [...yards, ...currentYards] },
    });

    toast.success("Transporteur assigner avec succès");
  } catch (error) {
    console.log(error);
    toast.error("Merci de contacter l'équipe DEV");
  }
};

export const setUserDetails = (transporterId, transporterYards, userId, userEmail) => async () => {
  try {
    transporterYards.forEach(async (yard) => {
      await db
        .collection('yards')
        .doc(yard)
        .collection('fournisseurs')
        .doc(transporterId)
        .update({ managerVbs: userEmail });
    });

    await db.collection('users').doc(userId).set({ companyName: transporterId });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getTransporterVBS = () => async (dispatch) => {
  try {
    let yardNames = [];

    const yards = await db.collection("yards").where("VBS", "==", true).get();

    yards.docs.map(async (doc) => {
      const { nom } = doc.data();
      return yardNames.push(nom);
    });
    const response = await Promise.all(
      yardNames.map(async (name) => {
        const res = await db
          .collection("yards")
          .doc(name)
          .collection("fournisseurs")
          .where("activity", "==", "Transporter")
          .get();

        return res.docs.map((doc) => {
          return {
            ...doc.data(),
            yard: [name],
            actifVBSParcs: doc.data().actifVBSParcs ? [...doc.data().actifVBSParcs] : [],
          };
        });
      })
    );
    const uniqueTransporters = unique(response.flat());
    dispatch({
      type: GET_TRANSPORTERS_VBS,
      payload: uniqueTransporters,
    });
  } catch (error) {
    console.log(error);
  }
};
/* export const getAllTransportersVBS = (yardsVBS) => (dispatch) => {
  let allTransporters = [];
  let allIdsByYards = [];

  yardsVBS.forEach(async (yard) => {
    db.collection("yards")
      .doc(yard)
      .collection("fournisseurs")
      .where("activity", "==", "Transporter")
      .get()
      .then((transporters) => {
        transporters.docs.forEach((doc) => {
          if (!allTransporters.includes(doc.id)) {
            allTransporters.push(doc.id);
          }

          if (!allIdsByYards.length) allIdsByYards.push({ id: doc.id, yards: [yard] });
          else {
            let done = false;
            for (let index = 0; index < allIdsByYards.length; index++) {
              const element = allIdsByYards[index];
              if (element.id === doc.id) {
                element.yards.push(yard);
                done = true;
                break;
              }
            }

            if (!done) allIdsByYards.push({ id: doc.id, yards: [yard] });
          }
        });
      });
  });

  dispatch({
    type: GET_TRANSPORTERS_VBS,
    payload: allTransporters,
  });
  /*  dispatch({
    type: SET_TRANSPORTER_YARDS,
    payload: allIdsByYards,
  });} */
