import { EDIT_USER } from "actions/types";
import { LOGOUT } from "actions/types";
import {
  GET_USERS_PER_YARD,
  CREATE_USER,
  GET_USERS_PER_COMPANYNAME,
  DISABLE_ENABLE_USER,
} from "actions/types";

const initialState = {
  yardUsers: [],
  selectedParc: "",
  usersPerCompany: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS_PER_YARD:
      return {
        ...state,
        yardUsers: payload,
      };
    case CREATE_USER:
      return {
        ...state,
        usersPerCompany: [payload, ...state.usersPerCompany],
      };
    case GET_USERS_PER_COMPANYNAME:
      return {
        ...state,
        usersPerCompany: payload,
      };
    case DISABLE_ENABLE_USER:
      return {
        ...state,
        usersPerCompany: state.usersPerCompany.map((user) => {
          if (user.email === payload.email) {
            user.disabled = payload.disabled;
          }
          return user;
        }),
      };
    case EDIT_USER:
      return {
        ...state,
        usersPerCompany: state.usersPerCompany.map((user) => {
          if (user.email === payload.email) {
            user.associatedDepot = payload.associatedDepot;
            user.displayName = payload.displayName;
            user.phoneNumber = payload.phoneNumber;
          }
          return user;
        }),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
