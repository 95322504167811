import { errorCodesEN, errorCodes } from 'config/config';

import setAuthToken from 'config/setAuthToken';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { auth, db, getHeaders } from '../config/firebase';
import { LOAD_USER, LOGOUT, LOGIN } from './types';

export const login = (email, password) => async (dispatch) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    const user = await db.collection('users').where('email', '==', email).get();
    const { roles } = user.docs[0].data();
    const prefLanguage = localStorage.getItem('prefLanguage');
    if (!prefLanguage) {
      localStorage.setItem('prefLanguage', 'en');
    }
    if (roles.includes('managerVBS') || roles.includes('adminVBS')) {
      const token = await getHeaders();
      localStorage.setItem('user', JSON.stringify(user.docs[0].data()));
      localStorage.setItem('token', token.Authorization);
      roles.includes('managerVBS') || roles.includes('adminVBS')
        ? null
        : localStorage.setItem('parcId', JSON.stringify(user.docs[0].data()['associatedDepot'][0]));
      dispatch({
        type: LOGIN,
        payload: user.docs[0].data()
      });
    } else {
      toast.error(
        i18next.language === 'fr'
          ? "Vous n'êtes pas autorisé pour utiliser cette plateforme"
          : 'Not allowed to use this platform'
      );
    }
  } catch (error) {
    i18next.language === 'fr'
      ? toast.error(errorCodes[error.code])
      : toast.error(errorCodesEN[error.code]);
  }
};

export const logout = () => (dispatch) => {
  try {
    dispatch({
      type: LOGOUT
    });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return auth.signOut();
  } catch (error) {
    console.log(error);
  }
};
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  let user = JSON.parse(localStorage.getItem('user'));
  dispatch({
    type: LOAD_USER,
    payload: user
  });
};
