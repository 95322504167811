// @mui material components
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import { errorCodes, errorCodesEN } from 'config/config';
import PreviewIcon from '@mui/icons-material/Preview';
import { toast } from 'react-toastify';
import { getEirInStorage } from 'helpers/helpers';
// Material Dashboard 2 React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import { Button, Card, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import MDTypography from 'components/MDTypography';
// import { columns } from './tableHeader';
// import { columnsEN } from './tableHeaderEN';
import {
  DataGrid,
  frFR,
  enUS,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { getEirHistory, getTruckerDetails } from 'actions/vbs';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import exportToExcel from 'helpers/exportToExcel';
import { gateOpType } from 'config/config';
import { getParcSetup } from 'actions/vbs';

const EirHistory = ({
  associatedDepot,
  companyName,
  getEirHistory,
  eirList,
  t,
  parcData,
  getParcSetup
}) => {
  useEffect(() => {
    getParcSetup(localStorage.getItem('parcId'));
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    parc: localStorage.getItem('parcId'),
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DDT23:59:00'),
    transporterId: ''
  });
  useEffect(() => {
    if (JSON.stringify(eirList) !== JSON.stringify(list)) {
      setList(eirList);
    }
  }, [eirList]);
  const [pageSize, setPageSize] = useState(10);
  const [list, setList] = useState([]);
  // eslint-disable-next-line
  const { startDate, endDate, parc, transporterId } = formData;

  const handleChange = (e) => {
    setList([]);
    setFormData({ ...formData, parc: e.target.value });
    localStorage.setItem('parcId', e.target.value);
  };
  const handleStartDateChange = (value) => {
    setFormData({ ...formData, startDate: moment(value).format('YYYY-MM-DD') });
  };
  const handleEndDateChange = (value) => {
    setFormData({
      ...formData,
      endDate: moment(value).format()
    });
  };
  const submitSearch = async () => {
    setLoading(true);
    await getEirHistory({ ...formData, transporterId: companyName, associatedDepot });
    setLoading(false);
  };

  const columns = [
    {
      field: 'preview',
      headerName: t('details'),
      width: 80,
      renderCell: (params) => (
        <Button
          onClick={async () => {
            try {
              const file = await getEirInStorage(params.row);

              window.open(file, '_blank');
            } catch (error) {
              i18next.language === 'fr'
                ? toast.error(errorCodes[error.code])
                : toast.error(errorCodesEN[error.code]);
            }
          }}>
          <PreviewIcon />
        </Button>
      )
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 190,
      valueGetter: (params) =>
        `${moment(params.row.date.value).format(
          parcData.dateFormat === 'US' ? 'MM/DD/YYYY,hh:mm A' : 'DD/MM/YYYY,HH:mm'
        )} `
    },
    {
      field: 'gateType',
      headerName: t('Gate Type'),
      width: 130,
      valueGetter: (params) =>
        `${
          params.row.equipmentType === gateOpType.CHASSIS
            ? t('Chassis')
            : params.row.equipmentType !== gateOpType.CHASSIS && params.row.chassisNumber
            ? t('onWheels')
            : t('Container')
        } `
    },
    {
      field: 'numSerie',
      headerName: t('Container number'),
      width: 130,
      valueGetter: (params) =>
        `${params.row.equipmentType !== gateOpType.CHASSIS ? params.row.numSerie : '-'} `
    },
    {
      field: 'chassisNumber',
      headerName: t('Chassis number'),
      width: 130,
      valueGetter: (params) =>
        `${
          params.row.equipmentType === gateOpType.CHASSIS
            ? params.row.numSerie
            : params.row.equipmentType !== gateOpType.CHASSIS && params.row.chassisNumber
            ? params.row.chassisNumber
            : '-'
        } `
    },
    { field: 'parc', headerName: t('Yard'), width: 130 },
    { field: 'equipmentSize', headerName: t('Size'), width: 80 },
    { field: 'equipmentType', headerName: t('Type'), width: 130 },
    { field: 'codeIso', headerName: t('ISO code'), width: 80 },
    { field: 'type', headerName: t('Gate'), width: 130 },
    {
      field: 'emptyFull',
      headerName: t('Empty/Full'),
      width: 100,
      valueGetter: (params) => `${params.row.emptyFull ? t('Full') : t('Empty')} `
    },
    {
      field: 'booking',
      headerName: 'Booking',
      width: 130,
      valueGetter: (params) => `${params.row.booking || '-'} `
    },
    { field: 'armateur', headerName: t('Liner'), width: 130 },
    {
      field: 'immatriculation',
      headerName: t('immatriculation'),
      width: 130,
      valueGetter: (params) => `${params.row.immatriculation || ''} `
    },
    {
      field: 'isPlacement',
      headerName: 'Pos/Evac',
      width: 130,
      valueGetter: (params) =>
        `${params.row.isPlacement ? 'P' : params.row.evacuation ? 'E' : '-'} `
    },
    {
      field: 'remarque',
      headerName: t('Note'),
      width: 200,
      valueGetter: (params) => `${params.row.remarque || ''} `
    }
  ];

  const CustomToolbar = (props) => {
    return (
      <>
        <GridToolbarContainer {...props}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
            onClick={() => {
              const excelFormattedData = [];

              let formatedData = list.map((data) => {
                return {
                  Date: moment(data.date.value).format(
                    parcData && parcData.dateFormat === 'US'
                      ? 'MM-DD-YYYY, hh:mm A'
                      : 'DD-MM-YYYY, HH:mm'
                  ),
                  gateType:
                    data.equipmentSize === gateOpType.CHASSIS
                      ? t('Chassis')
                      : data.equipmentType !== gateOpType.CHASSIS && data.chassisNumber
                      ? t('onWheels')
                      : t('Container'),
                  serialNumber: data.equipmentType !== gateOpType.CHASSIS ? data.numSerie : '-',
                  chassis_number:
                    data.equipmentType === gateOpType.CHASSIS
                      ? data.numSerie
                      : data.equipmentType !== gateOpType.CHASSIS && data.chassisNumber
                      ? data.chassisNumber
                      : '-',
                  conType: data.equipmentSize,
                  conSize: data.equipmentType,
                  ISOcode: data.codeIso,
                  type: data.type,
                  liner: data.armateur,
                  client: data.client,
                  parc: data.parc,
                  emptyFull: data.emptyFull ? t('full') : t('empty'),
                  truckerplate: data.immatriculation,
                  eirId: data.eirId,
                  POS_EVAC: `${data.isPlacement ? 'P' : data.evacuation ? 'E' : '-'}`,
                  note: data.remarque
                };
              });
              formatedData.forEach((row) => {
                const obj = {};
                Object.entries(row).forEach((entry) => {
                  obj[t([entry[0]])] = entry[1];
                });
                excelFormattedData.push(obj);
              });
              exportToExcel(
                excelFormattedData,
                `${t('interchangeReport')} ${startDate} ${t('to')} ${endDate}`
              );
            }}>
            Export excel
          </Button>
        </GridToolbarContainer>
      </>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid spacing={{ xs: 2, md: 1 }} container>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid xs={12} md={2} item>
            <DesktopDatePicker
              label={t('startDate')}
              inputFormat="MM/dd/yyyy"
              value={startDate}
              name="startDate"
              onChange={(e) => handleStartDateChange(e)}
              renderInput={(params) => <TextField {...params} />}
              maxDate={Date.now()}
            />
          </Grid>
          <Grid xs={12} md={2} item>
            <DesktopDatePicker
              label={t('endDate')}
              inputFormat="MM/dd/yyyy"
              value={endDate}
              name="endDate"
              onChange={(e) => handleEndDateChange(e)}
              renderInput={(params) => <TextField {...params} />}
              maxDate={Date.now()}
            />
          </Grid>
        </LocalizationProvider>

        <Grid xs={12} md={6} item>
          <FormControl fullWidth>
            <InputLabel>{t('depot')}</InputLabel>
            <Select
              value={parc}
              label={t('depot')}
              name="parc"
              style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
              onChange={(e) => handleChange(e)}>
              {associatedDepot &&
                associatedDepot.length > 0 &&
                associatedDepot.map((depot) => (
                  <MenuItem value={depot} key={depot}>
                    {depot}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2} pl={2} item>
          {parc ? (
            <Button variant="contained" onClick={() => submitSearch()} style={{ color: 'white' }}>
              <ThreeSixtyIcon />
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {t('eirHistory')}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid
                  localeText={
                    ({
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `${from} - ${to} ${i18next.language === 'fr' ? 'sur' : 'of'} ${count}`,
                        labelRowsPerPage:
                          i18next.language === 'fr' ? 'Lignes par page' : 'Rows per page'
                      }
                    },
                    i18next.language === 'fr'
                      ? frFR.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText)
                  }
                  rows={list}
                  columns={columns}
                  // columns={i18next.language === 'fr' ? columns : columnsEN}
                  pageSize={pageSize}
                  autoHeight
                  rowsPerPageOptions={[10, 20, 30]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  loading={loading}
                  getRowId={(row) => row.date.value}
                  disableSelectionOnClick
                  pagination
                  components={{
                    Toolbar: CustomToolbar
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};
const mapStateToProps = (state) => ({
  truckerDetails: state.vbs.truckerDetails,
  associatedDepot: state.auth.loggedUser.associatedDepot,
  managerMail: state.auth.loggedUser.email,
  companyName: state.auth.loggedUser.companyName,
  eirList: state.vbs.eirHistory,
  loading: state.vbs.loading,
  parcData: state.vbs.parcData
});

export default connect(mapStateToProps, { getParcSetup, getTruckerDetails, getEirHistory })(
  withTranslation()(EirHistory)
);
