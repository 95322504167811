import { LOGOUT, LOAD_USER, LOGIN, GET_INFRASTRUCTURE_VERSION } from 'actions/types';

const initialState = {
  loggedUser: {},
  isAuthenticated: null,
  loading: true,
  version: ''
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        loggedUser: payload
      };
    case LOGOUT:
      return {
        isAuthenticated: null,
        loggedUser: {},
        loading: true
      };
    case LOAD_USER:
      return {
        ...state,
        loggedUser: payload ? payload : {},
        loading: payload ? false : true,
        isAuthenticated: payload ? true : null
      };
    case GET_INFRASTRUCTURE_VERSION:
      return {
        ...state,
        version: payload
      };
    default:
      return state;
  }
}
