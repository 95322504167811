export const errorCodes = {
  'auth/user-not-found': "Aucun compte n'est associé à cette adresse e-mail.",
  'auth/invalid-email': "L'adresse email saisie est invalide.",
  'auth/user-disabled': 'Le compte associé à cette adresse e-mail a été désactivé.',
  'auth/wrong-password': 'Mot de passe incorrect. Veuillez réessayer.',
  'auth/user-token-expired': 'Votre session a expirée. Veuillez vous reconnecter.',
  'auth/invalid-user-token': 'Votre session a expirée. Veuillez vous reconnecter.',
  'auth/network-request-failed':
    'Impossible de communiquer avec le serveur. Veuillez vérifier votre connexion à internet.',
  'storage/object-not-found': 'PDF non prêt'
};
export const errorCodesEN = {
  'auth/user-not-found': 'No account is associated with this email address.',
  'auth/invalid-email': 'The entered email address is invalid.',
  'auth/user-disabled': 'The account associated with this email address has been deactivated.',
  'auth/wrong-password': 'Incorrect password. Try Again.',
  'auth/user-token-expired': 'Your session has expired. Please log in again.',
  'auth/invalid-user-token': 'Your session has expired. Please log in again.',
  'auth/network-request-failed':
    'Unable to communicate with the server. Please check your internet connection.',
  'storage/object-not-found': 'PDF not ready'
};

export const URL = {
  // NOTE localhost

  //API_URL: 'http://localhost:8080'
  // NOTE cloud_Staging

  API_URL: 'https://staging-dot-f619-224415.ey.r.appspot.com'
  // NOTE cloud_Prod
  //API_URL: "https://f619-224415.ey.r.appspot.com",
};
export const HEADERS = { 'Content-Type': 'application/json' };
export const liners = ['CMA', 'MSK', 'ALT', 'MSC', 'HPL', 'OCL', 'MAR', 'ONE', 'EVE', 'YML'];
export const gates = ['Gate In', 'Gate Out'];
export const conTypes = [
  'Dry - General Purpose',
  'Dry HC - General Purpose',
  'Reefer',
  'HC Reefer',
  'Ventilated',
  'HC Ventilated',
  'Named Cargo',
  'Insulated',
  'HC Insulated',
  'Open Top',
  'Hard Top',
  'HC Open Top',
  'HC Hard Top',
  'Flat',
  'Flat collapsible',
  'Platform superstructure',
  'Plateform',
  'HC Flat collapsible',
  'HC Platform'
];
export const conSize = ['20', '40', '45', '48', '53', '60'];

export const status = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  COMPLETED: 'accepted',
  NOSHOW: 'autoClosed'
};
export const gateType = {
  drop_off: 'drop_off',
  pick_up: 'pick_up'
};
export const USDate = {
  13: '01',
  14: '02',
  15: '03',
  16: '04',
  17: '05',
  18: '06',
  19: '07',
  20: '08',
  21: '09',
  22: '10',
  23: '11'
};

export const gateOpType = { CONTAINER: 'Container', CHASSIS: 'Chassis', ONWHEELS: 'onWheels' };

export const initialConSize = ['20', '40', '45', '48', '53', '60'];

export const disableScheduler = {
  pointerEvents: 'none',
  opacity: 0.4
};
export const disableMonthScheduler = {
  pointerEvents: 'none',
  opacity: 0.8
};
