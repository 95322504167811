import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { status } from "config/config";

const CustomAppointement = ({ props, truckerDetails, setAppointementData, setVisibleDetails }) =>
  truckerDetails.companyName === props.data.truckerCompany ? (
    <Appointments.Appointment
      {...props.restProps}
      style={{
        ...props.style,
        backgroundColor:
          props.data.status === status.PENDING
            ? "#2BE095"
            : props.data.status === status.COMPLETED
            ? "#D2D2D2"
            : props.data.status === status.NOSHOW
            ? "orange"
            : "#B62000",
        borderRadius: "8px",
      }}
      onClick={() => {
        setAppointementData(props.data);
        setVisibleDetails(true);
      }}
    >
      {props.children}
    </Appointments.Appointment>
  ) : null;
export default CustomAppointement;
