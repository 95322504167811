import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import copy from 'copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// @mui material components
import Grid from '@mui/material/Grid';
// import CheckCircleIcon from '@mui/icons-material/TaskAltRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAdornment from '@mui/material/InputAdornment';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  MonthView,
  DateNavigator,
  DayView
} from '@devexpress/dx-react-scheduler-material-ui';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withTranslation } from 'react-i18next';
import {
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Switch,
  FormHelperText
} from '@mui/material';
import moment from 'moment-timezone';
import { conTypes, initialConSize, disableScheduler, disableMonthScheduler } from 'config/config';
import { connect } from 'react-redux';
import {
  getParcSetup,
  getTruckerDetails,
  addAppointment,
  getAllAppointment,
  getAllBookings,
  deleteAppointment,
  checkChassis,
  setDateRange,
  updateBooking,
  checkContainer,
  getIsoCodes
} from 'actions/vbs';
import CircularProgress from '@mui/material/CircularProgress';
import { gateOpType, gateType, status, USDate } from '../../config/config';
import CustomDialog from 'components/CustomComponent/CustomDialog/CustomDialog';
import {
  // forbiddenNumSerieValidator,
  divideWidthPerGate,
  forbiddenNumChassisValidator
} from 'helpers/helpers';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { generatePdf } from '../../helpers/pdfGenerator';
import PrintIcon from '@mui/icons-material/Print';
import Tooltip from '@mui/material/Tooltip';
import CustomAppointement from 'components/CustomComponent/CustomAppointement/CustomAppointement';

const formatTime = (hour, minute, dateFormat) => {
  if (dateFormat === 'US') {
    // if (localStorage.getItem('prefLanguage') === 'en') {
    return (
      (hour.length === 1 ? '0' + hour : USDate[hour]) +
      ':' +
      (minute.length === 1 ? '0' + minute : minute) +
      (hour.length === 1 ? ' AM' : ' PM')
    );
  } else
    return (
      (hour.length === 1 ? '0' + hour : hour) + ':' + (minute.length === 1 ? '0' + minute : minute)
    );
};

const ExternalViewSwitcher = ({ currentViewName, onChange, t }) => (
  <RadioGroup
    aria-label="Views"
    style={{ flexDirection: 'row' }}
    name="views"
    value={currentViewName}
    onChange={onChange}>
    <FormControlLabel value="Day" control={<Radio />} label={t('Day_vue')} />
    <FormControlLabel value="Work Week" control={<Radio />} label={t('Week_vue')} />
    <FormControlLabel value="Month" control={<Radio />} label={t('Month_Vue')} />
  </RadioGroup>
);

/* Main Component */
const Appointment = ({
  t,
  associatedDepot,
  managerMail,
  getParcSetup,
  parcData,
  truckerDetails,
  getTruckerDetails,
  addAppointment,
  appointments,
  getAllAppointment,
  getAllBookings,
  allBookings,
  loading,
  setDateRange,
  schedulerRange,
  getIsoCodes,
  isoCodes
}) => {
  const [conSize, setConSize] = useState(initialConSize);
  const [currentViewName, setCurrentViewName] = useState('Work Week');
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [AppointmentData, setAppointementData] = useState({});
  const [visibleForm, setVisibleForm] = useState(false);
  const [listIsoCode, setListIsoCode] = useState(
    isoCodes.isoCode ? Object.keys(isoCodes.isoCode) : []
  );
  const [disableOnGateout, setdisableOnGateout] = useState(false);
  const [formData, setFormData] = useState({
    serialNumber: '',
    parc: localStorage.getItem('parcId'),
    armateur: '',
    client: '',
    plate: '',
    conType: '',
    size: '',
    codeIso: '',
    action: '',
    isFull: false,
    startDate: '',
    endDate: '',
    status: status.PENDING,
    bookingId: '',
    truckerCompany: '',
    parcPrefix: '',
    truckerName: '',
    trucker_NID: '',
    linerNote: '',
    numPlomb: '',
    opType: '',
    chassisNumber: '',
    chassisSize: ''
  });
  const {
    serialNumber,
    parc,
    armateur,
    // eslint-disable-next-line
    client,
    plate,
    conType,
    size,
    codeIso,
    isFull,
    bookingId,
    action,
    truckerName,
    trucker_NID,
    linerNote,
    numPlomb,
    opType,
    chassisNumber,
    chassisSize
  } = formData;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingType, setBookingType] = useState(null);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [armateurError, setArmateurError] = useState(false);
  const [cdlError, setCdlError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [plateError, setPlateError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [codeIsoError, setCodeIsoError] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [bookingIdError, setBookingIdError] = useState(false);
  const [opTypeError, setOpTypeError] = useState(false);
  const [chassisNumberError, setChassisNumberError] = useState(false);
  const [chassisSizeError, setChassisSizeError] = useState(false);
  const [bookingTypeReserved, setBookingTypeReservedError] = useState(false);
  const [conTypeError, setConTypeError] = useState(false);
  const [sealNumberError, setSealNumberError] = useState(false);
  const [contExist, setconExistError] = useState(false);
  const [chassisExist, setChassisExistError] = useState(false);
  const [disableIsoCodeBooking, setDisableIsoCodeBooking] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);

  const bookingRef = useRef();
  //Function to add text to clipboard
  const copyToClipboard = () => {
    let copyText = bookingRef.current.outerText;

    let isCopy = copy(copyText);
    //Dispalying notification
    if (isCopy) {
      toast.success(t('Copied to Clipboard'));
    }
  };

  // Appointement Block
  const timeTableCellComponent = ({ children, startDate, endDate }) => {
    let appointmentPerCell = 0;
    let blockDoubleClick = false;
    //let passed = startDate <= Date.now() ? true : false;
    const gatesPerDuration = parcData && parseInt(parcData.VBS_Schedule.gates_possible);
    appointments.map((elm) => {
      if (
        moment(elm.startDate).format('DD-MM-YYYY, h:mm ') ===
        moment(startDate).format('DD-MM-YYYY, h:mm ')
      )
        appointmentPerCell++;
    });
    blockDoubleClick = appointmentPerCell >= gatesPerDuration ? true : false;
    return (
      <WeekView.TimeTableCell
        onDoubleClick={() => {
          setFormData({ ...formData, startDate: startDate, endDate: endDate });
          !blockDoubleClick ? setVisibleForm(true) : setVisibleForm(false);
        }}
        className={
          currentViewName === 'Day' &&
          parcData.VBS_Schedule.workingDays.includes(new Date(startDate).getDay())
            ? 'disableCell'
            : parcData &&
              startDate.getHours() >= parseInt(parcData.VBS_Schedule.break_time_hour) &&
              startDate.getMinutes() >= parseInt(parcData.VBS_Schedule.break_time_minute) &&
              endDate <=
                new Date(
                  endDate.getFullYear(),
                  endDate.getMonth(),
                  endDate.getDate(),
                  parseInt(parcData.VBS_Schedule.break_duration_hour) +
                    parseInt(parcData.VBS_Schedule.break_time_hour),
                  parseInt(parcData.VBS_Schedule.break_duration_minute) +
                    parseInt(parcData.VBS_Schedule.break_time_minute)
                )
            ? 'shadedPauseCell'
            : startDate <= Date.now()
            ? 'shadedCell'
            : blockDoubleClick
            ? 'disableCell'
            : null
        }>
        {children}
      </WeekView.TimeTableCell>
    );
  };
  const appointmentContent = ({ children, style }) => {
    if (currentViewName === 'Day' || currentViewName === 'Work Week') {
      return (
        <Appointments.Container
          style={{
            ...style,
            width: divideWidthPerGate(style.width)
          }}>
          {children}
        </Appointments.Container>
      );
    }

    return <Appointments.Container style={style}>{children}</Appointments.Container>;
  };
  const currentViewNameChange = (e) => {
    setCurrentViewName(e.target.value);
    setDateRange(currentDate, e.target.value);
  };
  const currentDateChange = (currentDate) => {
    setCurrentDate(currentDate);
    setDateRange(currentDate, currentViewName);
  };
  const AppoitementFormContent = () => {
    let isUsFormat1 = parcData.dateFormat === 'US' ? 'MM/DD/YYYY [hh:mm A' : 'DD/MM/YYYY [HH:mm';
    let isUsFormat2 = parcData.dateFormat === 'US' ? 'hh:mm A]' : 'HH:mm]';
    return (
      <MDBox p={3}>
        {parcData && (
          <>
            <Grid xs={12} mb={2} textAlign="center" item>
              <strong style={{ fontSize: 18 }}>
                {t('addAppointement')}
                {` : ${moment(formData.startDate).format(isUsFormat1)} | ${moment(
                  formData.endDate
                ).format(isUsFormat2)} `}
              </strong>
            </Grid>
            <Grid xs={12} mb={2} textAlign="center" item>
              <strong>{t('yardName')}: </strong> {parc}
            </Grid>
            <Grid container spacing={3} justifyContent="center">
              <Grid xs={12} md={6} item textAlign="center">
                <FormControl fullWidth>
                  <InputLabel>Action *</InputLabel>
                  <Select
                    required
                    value={action}
                    label="Action"
                    name="action"
                    style={{
                      paddingBottom: '10px',
                      textAlign: 'center',
                      paddingTop: '10px',
                      backgroundColor:
                        action === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                    }}
                    // style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    error={actionError}>
                    <MenuItem value={gateType.drop_off}>Drop-off</MenuItem>
                    <MenuItem value={gateType.pick_up}>Pick-up</MenuItem>
                  </Select>
                  {actionError ? (
                    <FormHelperText> {t('Merci de renseigner un type de gate')}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {action === gateType.drop_off ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    <InputLabel>{t('opType')} *</InputLabel>
                    <Select
                      required
                      value={opType ? opType : ''}
                      label={t('opType')}
                      name="opType"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          opType === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                      }}
                      // style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
                      onChange={(e) => handleInputChange(e)}
                      error={opTypeError}>
                      <MenuItem value={gateOpType.CONTAINER}>{t(gateOpType.CONTAINER)}</MenuItem>
                      <MenuItem value={gateOpType.CHASSIS}>{t(gateOpType.CHASSIS)}</MenuItem>
                      <MenuItem value={gateOpType.ONWHEELS}>{t(gateOpType.ONWHEELS)}</MenuItem>
                    </Select>
                    {opTypeError ? (
                      <FormHelperText> {t('pleaseSelectOpType')}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
              {action === gateType.pick_up ? (
                <Grid xs={12} md={6} item>
                  <TextField
                    required
                    id="outlined-basic"
                    label={t('bookingNumber')}
                    name="bookingId"
                    value={bookingId.toUpperCase()}
                    style={{
                      backgroundColor:
                        bookingIdError ||
                        (bookingDetails && checkReserved(bookingDetails)) ||
                        bookingId === ''
                          ? 'rgba(255, 6, 6, 0.05)'
                          : !(bookingIdError && checkReserved(bookingDetails))
                          ? 'rgba(217, 255, 184, 0.4)'
                          : null
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckCircleIcon
                            style={{
                              color:
                                bookingId === ''
                                  ? 'grey'
                                  : !bookingIdError && !checkReserved(bookingDetails)
                                  ? 'green'
                                  : (bookingDetails && checkReserved(bookingDetails)) ||
                                    bookingIdError
                                  ? 'red'
                                  : null
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => handleInputChange(e)}
                    fullWidth
                    helperText={bookingIdError ? `${t('invalidBooking')}` : ''}
                    error={bookingIdError}
                    variant="outlined"
                    color="success"
                  />
                </Grid>
              ) : null}

              {action === gateType.pick_up ? (
                <Grid xs={12} md={6} item>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={bookingDetails && disableSelectBooking(bookingDetails)}>
                    <InputLabel>{t('selectContainerNumber')} *</InputLabel>
                    <Select
                      required
                      value={bookingType ? bookingType : ''}
                      label={t('selectContainerNumber')}
                      name="bookingType"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          (bookingDetails && bookingDetails.length > 1 && bookingType === null) ||
                          bookingTypeReserved ||
                          (bookingDetails && checkReserved(bookingDetails))
                            ? 'rgba(255, 6, 6, 0.05)'
                            : bookingDetails && bookingType !== null
                            ? 'rgba(217, 255, 184, 0.4)'
                            : null
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={
                        (bookingDetails && bookingDetails.length > 1 && bookingType === null) ||
                        bookingTypeReserved ||
                        (bookingDetails && checkReserved(bookingDetails))
                      }>
                      {bookingDetails.map((couplet, index) => (
                        <MenuItem value={index + 1} key={index} disabled={couplet.reserved}>
                          {couplet.conType === 'Chassis'
                            ? `${couplet.numChassis ? couplet.numChassis : ''}  ${
                                couplet.conType
                              } - ${couplet.conSize} ${couplet.reserved ? t('booked') : ''}`
                            : couplet.isFull && couplet.serialNumber
                            ? `${couplet.serialNumber} - ${couplet.conType} - ${
                                couplet.conSize
                              } - ${t('full')} ${couplet.reserved ? t('booked') : ''}`
                            : couplet.isFull && !couplet.serialNumber
                            ? `${couplet.conType} - ${couplet.conSize} - ${t('full')} ${
                                couplet.reserved ? t('booked') : ''
                              }`
                            : `${couplet.conType} - ${couplet.conSize} - ${t('empty')} ${
                                couplet.reserved ? t('booked') : ''
                              }`}
                        </MenuItem>
                      ))}
                    </Select>
                    {bookingTypeReserved || (bookingDetails && checkReserved(bookingDetails)) ? (
                      <FormHelperText style={{ color: '#f44335', textAlign: 'center' }}>
                        {' '}
                        {bookingTypeReserved && t('Merci de renseigner un type de booking')}
                        {bookingDetails && checkReserved(bookingDetails) && t('Already booked')}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
              {action === gateType.pick_up ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    <InputLabel>{t('opType')} *</InputLabel>
                    <Select
                      required
                      value={opType ? opType : ''}
                      label={t('opType')}
                      name="opType"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          opType === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={opTypeError}
                      disabled>
                      <MenuItem value={gateOpType.CONTAINER}> {t(gateOpType.CONTAINER)}</MenuItem>
                      <MenuItem value={gateOpType.CHASSIS}>{t(gateOpType.CHASSIS)}</MenuItem>
                      <MenuItem value={gateOpType.ONWHEELS}>{t(gateOpType.ONWHEELS)}</MenuItem>
                    </Select>
                    {opTypeError ? (
                      <FormHelperText> {t('pleaseSelectOpType')}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
              {(action === gateType.drop_off && opType === gateOpType.CONTAINER) ||
              (action === gateType.drop_off && opType === gateOpType.ONWHEELS) ? (
                <Grid xs={12} md={6} item>
                  <TextField
                    disabled={disableOnGateout}
                    required
                    id="outlined-basic"
                    label={t('serialNumber')}
                    name="serialNumber"
                    value={serialNumber}
                    style={{
                      backgroundColor:
                        !forbiddenNumSerieValidator(serialNumber) || serialNumber === ''
                          ? 'rgba(255, 6, 6, 0.05)'
                          : 'rgba(217, 255, 184, 0.4)'
                    }}
                    inputProps={{ maxLength: 11 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckCircleIcon
                            style={{
                              color:
                                serialNumber === ''
                                  ? 'grey'
                                  : forbiddenNumSerieValidator(serialNumber)
                                  ? 'green'
                                  : !forbiddenNumSerieValidator(serialNumber)
                                  ? 'red'
                                  : null
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      handleInputChange(e) && forbiddenNumSerieValidator(e.target.value);
                    }}
                    fullWidth
                    helperText={
                      serialNumber.length > 9 && !forbiddenNumSerieValidator(serialNumber)
                        ? `${t('invalidSerialNumber')}`
                        : ''
                    }
                    error={serialNumber.length > 9 && !forbiddenNumSerieValidator(serialNumber)}
                  />
                </Grid>
              ) : null}
              {(action === gateType.drop_off && opType === gateOpType.CONTAINER) ||
              (action === gateType.drop_off && opType === gateOpType.ONWHEELS) ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    <InputLabel>{t('liner')} *</InputLabel>
                    <Select
                      required
                      value={armateur ? armateur : ''}
                      label={t('liner')}
                      name="armateur"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          armateur === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={armateurError}
                      disabled={disableOnGateout}>
                      {Object.keys(parcData.shipOwnerClient)
                        .sort()
                        .map((liner) => (
                          <MenuItem value={liner} key={liner}>
                            {liner}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    {armateurError ? (
                      <FormHelperText> {t('Merci de renseigner un armateur')}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}

              {opType === gateOpType.CONTAINER || opType === gateOpType.ONWHEELS ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    <InputLabel>{t('conType')} *</InputLabel>
                    <Select
                      required
                      value={conType ? conType : ''}
                      label={t('conType')}
                      name="conType"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          conTypeError || conType === ''
                            ? 'rgba(255, 6, 6, 0.05)'
                            : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={conTypeError}
                      disabled={disableOnGateout}>
                      {conTypes.sort().map((conTypes) => (
                        <MenuItem value={conTypes} key={conTypes}>
                          {conTypes}
                        </MenuItem>
                      ))}
                    </Select>
                    {conTypeError ? (
                      <FormHelperText>
                        {t('Merci de renseigner un type de conteneur')}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
              {opType == gateOpType.CONTAINER ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    <InputLabel>{t('conSize')} *</InputLabel>
                    <Select
                      required
                      value={size ? size : ''}
                      label={t('conSize')}
                      name="size"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          sizeError || size === ''
                            ? 'rgba(255, 6, 6, 0.05)'
                            : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={sizeError}
                      disabled={disableOnGateout}>
                      {conSize.map((conSize) => (
                        <MenuItem value={conSize} key={conSize}>
                          {conSize}
                        </MenuItem>
                      ))}
                    </Select>
                    {sizeError ? (
                      <FormHelperText>
                        {' '}
                        {t('Merci de renseigner une taille conteneur')}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : opType == gateOpType.CHASSIS ? (
                <>
                  <Grid xs={12} md={6} item>
                    <TextField
                      required
                      id="outlined-basic"
                      label={t('chassis_number')}
                      name="chassisNumber"
                      value={chassisNumber}
                      variant="outlined"
                      style={{
                        backgroundColor:
                          forbiddenNumChassisValidator(chassisNumber) || chassisNumber === ''
                            ? 'rgba(255, 6, 6, 0.05)'
                            : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 11 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CheckCircleIcon
                              style={{
                                color:
                                  chassisNumber === ''
                                    ? 'grey'
                                    : !forbiddenNumChassisValidator(chassisNumber)
                                    ? 'green'
                                    : forbiddenNumChassisValidator(chassisNumber)
                                    ? 'red'
                                    : null
                              }}
                            />
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        chassisNumber.length > 4 && forbiddenNumChassisValidator(chassisNumber)
                          ? `${t('invalidChassisSerialNumber')}`
                          : chassisExist
                          ? `${t('chassisNotAvailable')}`
                          : ''
                      }
                      error={
                        (chassisNumber.length > 4 && forbiddenNumChassisValidator(chassisNumber)) ||
                        chassisNumberError ||
                        chassisExist
                      }
                      disabled={disableOnGateout}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <FormControl fullWidth>
                      <InputLabel>{t('chassisSize')} *</InputLabel>
                      <Select
                        required
                        value={chassisSize ? chassisSize : ''}
                        label={t('chassisSize')}
                        name="chassisSize"
                        style={{
                          paddingBottom: '10px',
                          textAlign: 'center',
                          paddingTop: '10px',
                          backgroundColor:
                            chassisSizeError || chassisSize === ''
                              ? 'rgba(255, 6, 6, 0.05)'
                              : 'rgba(217, 255, 184, 0.4)'
                        }}
                        onChange={(e) => handleInputChange(e)}
                        error={chassisSizeError}
                        disabled={disableOnGateout}>
                        {conSize.map((conSize) => (
                          <MenuItem value={conSize} key={conSize}>
                            {conSize}
                          </MenuItem>
                        ))}
                      </Select>
                      {chassisSizeError ? (
                        <FormHelperText>
                          {' '}
                          {t('Merci de renseigner une taille conteneur')}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>{' '}
                </>
              ) : opType === gateOpType.ONWHEELS ? (
                <>
                  <Grid xs={12} md={6} item>
                    <FormControl fullWidth>
                      <InputLabel>{t('conSize')} *</InputLabel>
                      <Select
                        required
                        value={size ? size : ''}
                        label={t('conSize')}
                        name="size"
                        style={{
                          paddingBottom: '10px',
                          textAlign: 'center',
                          paddingTop: '10px',
                          backgroundColor:
                            sizeError || size === ''
                              ? 'rgba(255, 6, 6, 0.05)'
                              : 'rgba(217, 255, 184, 0.4)'
                        }}
                        onChange={(e) => handleInputChange(e)}
                        error={sizeError}
                        disabled={disableOnGateout}>
                        {conSize.map((conSize) => (
                          <MenuItem value={conSize} key={conSize}>
                            {conSize}
                          </MenuItem>
                        ))}
                      </Select>
                      {sizeError ? (
                        <FormHelperText>
                          {' '}
                          {t('Merci de renseigner une taille conteneur')}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <FormControl fullWidth>
                      {action === gateType.drop_off ? (
                        <InputLabel>{t('ISOcode')} * </InputLabel>
                      ) : (
                        <InputLabel>{t('ISOcode')}</InputLabel>
                      )}
                      <Select
                        value={codeIso}
                        label={t('ISOcode')}
                        name="codeIso"
                        style={{
                          paddingBottom: '10px',
                          textAlign: 'center',
                          paddingTop: '10px',
                          backgroundColor:
                            action === gateType.pick_up
                              ? null
                              : (codeIsoError || codeIso === '') && action === gateType.drop_off
                              ? 'rgba(255, 6, 6, 0.05)'
                              : 'rgba(217, 255, 184, 0.4)'
                        }}
                        onChange={(e) => handleInputChange(e)}
                        error={codeIsoError}
                        disabled={disableIsoCodeBooking}>
                        {listIsoCode &&
                          listIsoCode.map((code) => {
                            return (
                              <MenuItem value={code} key={code}>
                                {code}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {codeIsoError ? (
                        <FormHelperText>{t('Merci de renseigner un code Iso')}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      disabled={disableOnGateout}
                      required
                      id="outlined-basic"
                      label={t('chassis_number')}
                      name="chassisNumber"
                      value={chassisNumber}
                      variant="outlined"
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 11 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CheckCircleIcon
                              style={{
                                color:
                                  chassisNumber === ''
                                    ? 'grey'
                                    : !forbiddenNumChassisValidator(chassisNumber)
                                    ? 'green'
                                    : forbiddenNumChassisValidator(chassisNumber)
                                    ? 'red'
                                    : null
                              }}
                            />
                          </InputAdornment>
                        )
                      }}
                      style={{
                        backgroundColor:
                          chassisNumberError ||
                          chassisNumber === '' ||
                          forbiddenNumChassisValidator(chassisNumber)
                            ? 'rgba(255, 6, 6, 0.05)'
                            : 'rgba(217, 255, 184, 0.4)'
                      }}
                      helperText={
                        chassisNumber.length > 4 && forbiddenNumChassisValidator(chassisNumber)
                          ? `${t('invalidChassisSerialNumber')}`
                          : ''
                      }
                      error={
                        (chassisNumber.length > 4 && forbiddenNumChassisValidator(chassisNumber)) ||
                        chassisNumberError
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <FormControl fullWidth>
                      <InputLabel>{t('chassisSize')} *</InputLabel>
                      <Select
                        required
                        value={chassisSize ? chassisSize : ''}
                        label={t('chassisSize')}
                        name="size"
                        style={{
                          paddingBottom: '10px',
                          textAlign: 'center',
                          paddingTop: '10px',
                          backgroundColor:
                            chassisSizeError || chassisSize === ''
                              ? 'rgba(255, 6, 6, 0.05)'
                              : 'rgba(217, 255, 184, 0.4)'
                        }}
                        onChange={(e) => handleInputChange(e)}
                        error={chassisSizeError}
                        disabled>
                        {conSize.map((conSize) => (
                          <MenuItem value={conSize} key={conSize}>
                            {conSize}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {chassisSizeError ? (
                        <FormHelperText>{t('pleaseSelectChassisSize')}</FormHelperText>
                      ) : null} */}
                    </FormControl>
                  </Grid>
                </>
              ) : null}

              {opType === gateOpType.CONTAINER ? (
                <Grid xs={12} md={6} item>
                  <FormControl fullWidth>
                    {action === gateType.drop_off ? (
                      <InputLabel>{t('ISOcode')} * </InputLabel>
                    ) : (
                      <InputLabel>{t('ISOcode')}</InputLabel>
                    )}
                    <Select
                      value={codeIso}
                      label={t('ISOcode')}
                      name="codeIso"
                      style={{
                        paddingBottom: '10px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        backgroundColor:
                          action === gateType.pick_up
                            ? null
                            : (codeIsoError || codeIso === '') && action === gateType.drop_off
                            ? 'rgba(255, 6, 6, 0.05)'
                            : 'rgba(217, 255, 184, 0.4)'
                      }}
                      onChange={(e) => handleInputChange(e)}
                      error={codeIsoError}
                      disabled={disableIsoCodeBooking}>
                      {listIsoCode &&
                        listIsoCode.map((code) => {
                          return (
                            <MenuItem value={code} key={code}>
                              {code}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {codeIsoError ? (
                      <FormHelperText>{t('Merci de renseigner un code Iso')}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
              <Grid xs={12} md={6} item>
                <FormControl fullWidth>
                  <InputLabel>{t('truckPlate')} *</InputLabel>
                  <Select
                    required
                    value={plate}
                    style={{
                      paddingBottom: '10px',
                      textAlign: 'center',
                      paddingTop: '10px',
                      backgroundColor:
                        plate === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                    }}
                    label={t('truckPlate')}
                    name="plate"
                    // style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
                    onChange={(e) => handleInputChange(e)}
                    error={plateError}>
                    <MenuItem value="other">{t('other')}</MenuItem>
                    {truckerDetails.trucks &&
                      truckerDetails['trucks'].map((truck) => (
                        <MenuItem value={truck} key={truck}>
                          {truck}
                        </MenuItem>
                      ))}
                  </Select>
                  {plateError ? (
                    <FormHelperText> {t('Merci de renseigner une plaque camion')}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {/* <Grid xs={12} md={6} item>
                <TextField
                  id="outlined-basic"
                  label={t('driverName')}
                  name="truckerName"
                  value={truckerName}
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  autoFocus
                />
              </Grid> */}
              <Grid xs={12} md={6} item>
                <FormControl fullWidth>
                  <InputLabel>{t('driverName')} *</InputLabel>
                  <Select
                    required
                    value={truckerName}
                    label={t('driverName')}
                    name="truckerName"
                    style={{
                      paddingBottom: '10px',
                      textAlign: 'center',
                      paddingTop: '10px',
                      backgroundColor:
                        truckerName === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                    }}
                    onChange={(e) => handleInputChange(e)}
                    error={nameError}>
                    <MenuItem value="other">{t('other')}</MenuItem>
                    {truckerDetails &&
                      truckerDetails.cdlAndName &&
                      truckerDetails.cdlAndName.map((data, index) => (
                        <MenuItem value={data.name} key={index}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {nameError ? (
                    <FormHelperText>
                      {' '}
                      {t('Merci de renseigner le prénom et le nom du chauffeur')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} item>
                <FormControl fullWidth>
                  <InputLabel>{t('cdl')} *</InputLabel>
                  <Select
                    required
                    value={trucker_NID}
                    label={t('cdl')}
                    name="trucker_NID"
                    style={{
                      paddingBottom: '10px',
                      textAlign: 'center',
                      paddingTop: '10px',
                      backgroundColor:
                        trucker_NID === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                    }}
                    onChange={(e) => handleInputChange(e)}
                    error={cdlError}
                    disabled>
                    {/* <MenuItem value="other">{t('other')}</MenuItem>
                    {truckerDetails.cins &&
                      truckerDetails['cins'].map((cin) => (
                        <MenuItem value={cin} key={cin}>
                          {cin}
                        </MenuItem>
                      ))}
                  </Select> */}
                    <MenuItem value="other">{t('other')}</MenuItem>
                    {truckerDetails &&
                      truckerDetails.cdlAndName &&
                      truckerDetails.cdlAndName.map((data, index) => (
                        <MenuItem value={data.cdl} key={index}>
                          {data.cdl}
                        </MenuItem>
                      ))}
                  </Select>
                  {cdlError ? (
                    <FormHelperText> {t('Merci de renseigner cdl')}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {/* <Grid xs={12} md={6} item>
                <TextField
                  id="outlined-basic"
                  label={t('CIN')}
                  name="trucker_NID"
                  value={trucker_NID}
                  variant="outlined" 
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  autoFocus
                />
              </Grid> */}
              {opType === gateOpType.CONTAINER || opType === gateOpType.ONWHEELS ? (
                <Grid xs={12} md={6} item textAlign="center">
                  {t('empty')}
                  <Switch
                    checked={isFull}
                    name="isFull"
                    onChange={(e) => handleInputChange(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disableOnGateout}
                  />
                  {isFull ? <strong>{t('full')}</strong> : <>{t('full')}</>}
                </Grid>
              ) : null}
              {isFull && action === gateType.drop_off ? (
                <Grid xs={12} md={6} item textAlign="center">
                  <TextField
                    required
                    id="outlined-basic"
                    label={t('numPlomb')}
                    name="numPlomb"
                    value={numPlomb}
                    variant="outlined"
                    onChange={(e) => handleInputChange(e)}
                    fullWidth
                    autoFocus
                    style={{
                      backgroundColor:
                        numPlomb === '' || sealNumberError
                          ? 'rgba(255, 6, 6, 0.05)'
                          : 'rgba(217, 255, 184, 0.4)'
                    }}
                    helperText={sealNumberError ? t('Merci de renseigner le numéro de plomb') : ''}
                    error={sealNumberError}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckCircleIcon
                            style={{
                              color:
                                numPlomb === ''
                                  ? 'grey'
                                  : numPlomb.length > 0
                                  ? 'green'
                                  : sealNumberError
                                  ? 'red'
                                  : null
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              ) : isFull && action === gateType.pick_up ? (
                <Grid xs={12} md={6} item>
                  <TextField
                    required
                    id="outlined-basic"
                    label={t('serialNumber')}
                    name="serialNumber"
                    value={serialNumber}
                    variant="outlined"
                    style={{
                      backgroundColor:
                        serialNumber === '' ? 'rgba(255, 6, 6, 0.05)' : 'rgba(217, 255, 184, 0.4)'
                    }}
                    onChange={(e) =>
                      handleInputChange(e) && forbiddenNumSerieValidator(e.target.value)
                    }
                    fullWidth
                    inputProps={{ maxLength: 11 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckCircleIcon
                            style={{
                              color:
                                serialNumber === ''
                                  ? 'grey'
                                  : forbiddenNumSerieValidator(serialNumber)
                                  ? 'green'
                                  : !forbiddenNumSerieValidator(serialNumber)
                                  ? 'red'
                                  : null
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                    helperText={
                      // serialNumber.length > 9 && !forbiddenNumSerieValidator(serialNumber)
                      !forbiddenNumSerieValidator(serialNumber)
                        ? `${t('invalidSerialNumber')}`
                        : isOnHold
                        ? `${t('containerOnHold')}`
                        : contExist
                        ? `${t('containerExist')}`
                        : ''
                    }
                    error={
                      // serialNumber.length > 9 && !forbiddenNumSerieValidator(serialNumber)
                      !forbiddenNumSerieValidator(serialNumber)
                        ? true
                        : isOnHold
                        ? true
                        : contExist
                        ? true
                        : false
                    }
                    disabled={disableOnGateout}
                  />
                </Grid>
              ) : null}
              {linerNote ? (
                <Grid xs={12} item>
                  <strong>{t('linerNote')} : </strong>
                  {linerNote}
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
      </MDBox>
    );
  };
  const forbiddenNumSerieValidator = (value) => {
    if ((size && size == 48) || (size && size == 53) || (size && size == 60)) {
      const regex = new RegExp('^[A-Za-z]{4}\\d{6,7}$');
      if (!regex.test(value)) {
        return false;
      }
      if (value.length < 10) {
        console.log('try');
        return false;
      }
      return true;
    } else {
      const nameRe = new RegExp('^[A-Za-z]{4}\\d{7}$');
      const corresp = {
        A: 10,
        B: 12,
        C: 13,
        D: 14,
        E: 15,
        F: 16,
        G: 17,
        H: 18,
        I: 19,
        J: 20,
        K: 21,
        L: 23,
        M: 24,
        N: 25,
        O: 26,
        P: 27,
        Q: 28,
        R: 29,
        S: 30,
        T: 31,
        U: 32,
        V: 34,
        W: 35,
        X: 36,
        Y: 37,
        Z: 38
      };
      const numSerie = value.toUpperCase();
      let result = 0;
      if (nameRe.test(numSerie)) {
        for (let i = 0; i < numSerie.length - 1; i++) {
          if (i < 4) {
            result += corresp[numSerie[i]] * Math.pow(2, i);
          } else {
            result += parseInt(numSerie[i]) * Math.pow(2, i);
          }
        }
      } else {
        return false;
      }
      const finalResult = result - ~~(result / 11) * 11 === 10 ? 0 : result - ~~(result / 11) * 11;
      return finalResult === parseInt(numSerie[numSerie.length - 1]);
    }
  };
  // useEffect Block
  useEffect(() => {
    // if ((size && size === 48) || (size && size === 53) || (size && size === 60)) {
    forbiddenNumSerieValidator(serialNumber);
    // }
  }, [serialNumber, size, conType]);
  useEffect(() => {
    if (parcData && parcData.timezone) {
      setDateRange(currentDate, currentViewName);
      const unsubscribe = getAllAppointment(parc, parcData.timezone, schedulerRange);
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [parc, parcData, schedulerRange.startDate, schedulerRange.endDate]);

  const initIsoCode = async () => {
    try {
      let allIsoCode = [];
      allIsoCode = await getIsoCodes();
      let isoCodeListFromFirebase = Object.keys(allIsoCode);

      setListIsoCode(isoCodeListFromFirebase);
    } catch (error) {
      console.log('error in initIsoCode', error);
    }
  };

  useEffect(() => {
    initIsoCode();
  }, [armateur, action, opType]);
  const getCodeIsoWhenContypeSelect = async () => {
    try {
      await getIsoCodes();
      if (parcData && conType && size) {
        let arrIsoCodes = [];

        arrIsoCodes = Object.keys(isoCodes.isoCode).filter((code) => {
          return (
            isoCodes.isoCode[code]['type'] === conType &&
            isoCodes.isoCode[code]['size'] === parseInt(size)
          );
        });
        setListIsoCode(arrIsoCodes);
      }
      if (
        conType == 'Dry HC - General Purpose' ||
        conType == 'HC Reefer' ||
        conType == gateOpType.CHASSIS ||
        opType === gateOpType.CHASSIS
      ) {
        setConSize(initialConSize);
      } else {
        setConSize(conSize.filter((size) => size != 48 && size != 53 && size != 60));
      }
    } catch (error) {
      console.log('error getCodeIsoWhenContypeSelect', error);
    }
  };
  useEffect(() => {
    getCodeIsoWhenContypeSelect();
  }, [conType, size, opType]);

  useEffect(() => {
    if (action === gateType.pick_up) {
      getAllBookings(parc);
      //setdisableOnGateout(true);
    } else setdisableOnGateout(false);
  }, [action]);

  useEffect(() => {
    setFormData({
      ...formData,
      serialNumber: '',
      armateur: '',
      client: '',
      plate: '',
      conType: '',
      size: '',
      codeIso: '',
      isFull: false,
      status: status.PENDING,
      bookingId: '',
      truckerCompany: '',
      filesIdPrefix: '',
      truckerName: '',
      trucker_NID: '',
      linerNote: '',
      numPlomb: '',
      opType: ''
    });
    setBookingType(null);
    setBookingDetails([]);
    if (allBookings && allBookings.length > 0 && bookingId !== '') {
      const selectedBooking = allBookings.filter((elm) => elm.id === bookingId);
      if (!selectedBooking.length) {
        setBookingIdError(true);
      } else {
        setBookingIdError(false);
        setBookingDetails(selectedBooking[0].couplet);
        // setBookingType(selectedBooking[0]?.couplet.length);
      }
      if (bookingId && selectedBooking && selectedBooking[0]?.couplet.length === 1) {
        setBookingType(selectedBooking[0]?.couplet.length);
      } else {
        setBookingType(null);
      }

      setFormData({
        ...formData,
        armateur: selectedBooking[0]?.armateur,
        client: selectedBooking[0]?.clientId
      });
    }
  }, [bookingId]);

  useEffect(() => {
    getParcSetup(localStorage.getItem('parcId'));
    getTruckerDetails(localStorage.getItem('parcId'), managerMail);
  }, []);
  useEffect(async () => {
    let isoCode = '';
    let sealNumber = '';
    if (allBookings && allBookings.length > 0 && bookingType && bookingDetails) {
      const selectedCouplet = bookingDetails[bookingType - 1];
      if (selectedCouplet.serialNumber) {
        const response = await checkContainer(parc, selectedCouplet.serialNumber);
        isoCode = response.exists ? response.isoCode : '';
        sealNumber = response.exists && response.isFull ? response.sealNumber : '';
        setDisableIsoCodeBooking(true);
      } else {
        setDisableIsoCodeBooking(false);
      }
      setFormData({
        ...formData,
        conType: selectedCouplet.conType,
        size: selectedCouplet.conSize,
        linerNote:
          selectedCouplet.complimentaryInfo && selectedCouplet.complimentaryInfo.generalComments
            ? selectedCouplet.complimentaryInfo.generalComments
            : selectedCouplet.complimentaryInfo.temperatureValue
            ? `${selectedCouplet.complimentaryInfo.temperatureValue} C° - ${selectedCouplet.complimentaryInfo.temperatureScale}`
            : '',
        isFull: selectedCouplet.isFull ? selectedCouplet.isFull : false,
        serialNumber: selectedCouplet.serialNumber ? selectedCouplet.serialNumber : '',
        codeIso: isoCode,
        opType:
          selectedCouplet.typeGate && selectedCouplet.typeGate
            ? selectedCouplet.typeGate
            : 'Container',
        chassisSize: selectedCouplet.conSize,
        chassisNumber: selectedCouplet.numChassis ? selectedCouplet.numChassis : '',
        numPlomb: sealNumber
      });

      if (action === gateType.pick_up) setdisableOnGateout(true);
    }
  }, [bookingType, bookingDetails]);
  /**Autofil Driver Name */

  useEffect(() => {
    if (truckerName === 'other') {
      setFormData({ ...formData, trucker_NID: 'other' });
    } else if (truckerName && truckerName !== 'other' && truckerName.length > 0) {
      const filteredCdl =
        truckerDetails &&
        truckerDetails.cdlAndName &&
        truckerDetails.cdlAndName.filter((el) => el.name === truckerName);
      setFormData({ ...formData, trucker_NID: filteredCdl[0].cdl });
    }
  }, [truckerName]);

  useEffect(() => {
    if (opType === gateOpType.ONWHEELS && size) {
      setFormData({ ...formData, chassisSize: size });
    }
  }, [opType, size]);

  // Function Block
  const executeScroll = () => {
    window.scrollTo({
      top: 500,
      left: 0,
      behavior: 'smooth'
    });
  };
  // DISABLE SELECT BOOKING
  const disableSelectBooking = (bookingDetails) => {
    return bookingDetails && bookingDetails.length === 1 ? true : false;
  };

  /** check reserved for booking of length 1 */
  const checkReserved = (bookingDetails) => {
    let reserved = false;
    bookingDetails && bookingDetails.length === 1 && bookingDetails[0].reserved
      ? (reserved = true)
      : (reserved = false);
    return reserved;
  };
  const reserved = bookingDetails && checkReserved(bookingDetails);
  const handleInputChange = (e) => {
    if (e.target.name === 'parc') {
      executeScroll();
      setFormData({ ...formData, parc: e.target.value });
      getParcSetup(e.target.value);
      getTruckerDetails(e.target.value, managerMail);
      localStorage.setItem('parcId', e.target.value);

      // getAllAppointment(e.target.value, parcData.timezone);
    }

    if (e.target.name === 'isFull') {
      if (e.target.checked) {
        setFormData({ ...formData, isFull: e.target.checked });
        return !e.target.value;
      }
      if (!e.target.checked) {
        setFormData({ ...formData, numPlomb: '', isFull: e.target.checked });
        return !e.target.value;
      }
    }
    if (e.target.name === 'bookingType') {
      setBookingType(e.target.value);
      return !e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      truckerCompany: truckerDetails.companyName
    });
    resetErrors();
  };
  const resetErrors = () => {
    setArmateurError(false);
    setPlateError(false);
    setConTypeError(false);
    setCodeIsoError(false);
    setBookingIdError(false);
    setActionError(false);
    setSizeError(false);
    setSealNumberError(false);
    setconExistError(false);
    setBookingTypeReservedError(false);
    setOpTypeError(false);
    setChassisNumberError(false);
    setChassisSizeError(false);
    setChassisExistError(false);
    setCdlError(false);
    setNameError(false);
  };
  const resetForm = () => {
    setFormData({
      ...formData,
      serialNumber: '',
      armateur: '',
      client: '',
      plate: '',
      conType: '',
      size: '',
      codeIso: '',
      action: '',
      isFull: false,
      startDate: '',
      endDate: '',
      status: status.PENDING,
      bookingId: '',
      truckerCompany: '',
      filesIdPrefix: '',
      truckerName: '',
      trucker_NID: '',
      linerNote: '',
      numPlomb: '',
      opType: '',
      chassisNumber: '',
      chassisSize: ''
    });
    setBookingType(null);
    setBookingDetails([]);
  };
  const handleClose = () => {
    setVisibleDetails(false);
  };
  const handleCloseForm = () => {
    resetForm();
    resetErrors();
    setVisibleForm(false);
  };
  const handleSubmit = async () => {
    if (!opType) {
      setOpTypeError(true);
    }
    if (action == gateType.drop_off && opType === gateOpType.CHASSIS && !chassisNumber) {
      setChassisNumberError(true);
    }
    if (
      action == gateType.drop_off &&
      opType === gateOpType.ONWHEELS &&
      forbiddenNumChassisValidator(chassisNumber)
    ) {
      setChassisNumberError(true);
    }
    if (
      (action == gateType.drop_off && opType === gateOpType.CHASSIS && !chassisSize) ||
      (action == gateType.drop_off && opType === gateOpType.ONWHEELS && !chassisSize)
    ) {
      setChassisSizeError(true);
    }
    if (!bookingType) {
      setBookingTypeReservedError(true);
    }
    if (
      (!armateur && opType === gateOpType.CONTAINER) ||
      (!armateur && opType === gateOpType.ONWHEELS)
    ) {
      setArmateurError(true);
    }
    if (!plate) {
      setPlateError(true);
    }
    if (!trucker_NID) {
      setCdlError(true);
    }
    if (!truckerName) {
      setNameError(true);
    }
    if (
      (!conType && opType === gateOpType.CONTAINER) ||
      (opType === gateOpType.ONWHEELS && !conType)
    ) {
      setConTypeError(true);
    }
    if (
      (!codeIso && action === gateType.drop_off && opType === gateOpType.CONTAINER) ||
      (action === gateType.drop_off && opType === gateOpType.ONWHEELS && !codeIso)
    ) {
      setCodeIsoError(true);
    }
    if (!bookingId) {
      setBookingIdError(true);
    }
    if (!action) {
      setActionError(true);
    }
    if ((!size && opType === gateOpType.CONTAINER) || (opType === gateOpType.ONWHEELS && !size)) {
      setSizeError(true);
    }
    if (
      (isFull && !numPlomb && opType === gateOpType.CONTAINER) ||
      (opType === gateOpType.ONWHEELS && isFull && !numPlomb)
    ) {
      setSealNumberError(true);
    }
    const isFullAndHasNumplomb =
      (isFull && !!numPlomb && opType === gateOpType.CONTAINER) ||
      (opType === gateOpType.ONWHEELS && isFull && !!numPlomb);
    if (
      (isFullAndHasNumplomb || !isFull) &&
      ((forbiddenNumSerieValidator(serialNumber) && action === gateType.drop_off) ||
        action === gateType.pick_up) &&
      armateur &&
      plate &&
      trucker_NID &&
      conType &&
      size &&
      ((codeIso && action === gateType.drop_off) || action === gateType.pick_up) &&
      ((bookingId && bookingType) || serialNumber.length > 9) &&
      ((opType === gateOpType.ONWHEELS && !forbiddenNumChassisValidator(chassisNumber)) ||
        opType === gateOpType.CONTAINER)
    ) {
      if (action === gateType.pick_up && isFull) {
        const container = await checkContainer(parc, serialNumber);
        if (container.exists) {
          if (container.isFull && !container.onHold) {
            setIsSubmitting(true);
            formData.parcPrefix = parcData.filesIdPrefix;
            formData.numPlomb = container.sealNumber;
            const truckerBooking = await addAppointment(formData, parcData.timezone);
            const selectedBooking = allBookings.find((elm) => elm.id === bookingId);
            action === gateType.pick_up
              ? await updateBooking(
                  parc,
                  selectedBooking,
                  bookingType,
                  appointments,
                  truckerBooking
                )
              : null;
            setVisibleForm(false);
            resetForm();
            setIsSubmitting(false);
          } else {
            setIsOnHold(true);
          }
        } else {
          setCodeIsoError(false);
          setconExistError(true);
        }
      } else {
        setIsSubmitting(true);
        formData.parcPrefix = parcData.filesIdPrefix;
        const truckerBooking = await addAppointment(formData, parcData.timezone);
        const selectedBooking = allBookings.find((elm) => elm.id === bookingId);
        action === gateType.pick_up
          ? await updateBooking(parc, selectedBooking, bookingType, appointments, truckerBooking)
          : null;
        setVisibleForm(false);
        resetForm();
        setIsSubmitting(false);
      }
    }
    if (
      (opType === gateOpType.CHASSIS &&
        chassisSize &&
        plate.length &&
        trucker_NID &&
        !forbiddenNumChassisValidator(chassisNumber)) ||
      (opType === gateOpType.CHASSIS &&
        bookingId &&
        bookingType &&
        trucker_NID &&
        action === gateType.pick_up &&
        plate.length)
    ) {
      if (action === gateType.pick_up) {
        const chassis = await checkChassis(parc, chassisNumber);
        if (chassis.exists && chassisNumber.length) {
          setIsSubmitting(true);
          formData.parcPrefix = parcData.filesIdPrefix;
          const truckerBooking = await addAppointment(formData, parcData.timezone);
          const selectedBooking = allBookings.find((elm) => elm.id === bookingId);

          await updateBooking(parc, selectedBooking, bookingType, appointments, truckerBooking);

          setVisibleForm(false);
          resetForm();
          setIsSubmitting(false);
        }
        if (!chassis.exists && chassisNumber.length) {
          setChassisExistError(true);
        }
        if (!chassis.exists && !chassisNumber.length) {
          setIsSubmitting(true);
          formData.parcPrefix = parcData.filesIdPrefix;
          const truckerBooking = await addAppointment(formData, parcData.timezone);
          const selectedBooking = allBookings.find((elm) => elm.id === bookingId);

          await updateBooking(parc, selectedBooking, bookingType, appointments, truckerBooking);

          setVisibleForm(false);
          resetForm();
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(true);
        formData.parcPrefix = parcData.filesIdPrefix;
        await addAppointment(formData, parcData.timezone);
        setVisibleForm(false);
        resetForm();
        setIsSubmitting(false);
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox style={{ fontSize: 15 }}>
        {/* {parcData ? ( */}
        <Card>
          {/* <MDBox mb={3}> */}
          <Grid container style={{ padding: '5px' }}>
            <Grid md={3} item></Grid>
            <Grid xs={12} md={6} item>
              <FormControl fullWidth>
                <InputLabel>{t('depot')}</InputLabel>
                <Select
                  value={parc}
                  label={t('depot')}
                  name="parc"
                  style={{
                    marginTop: '10PX',
                    paddingBottom: '10px',
                    textAlign: 'center',
                    paddingTop: '10px',
                    backgroundColor: 'rgb(240,242,245)'
                    // marginLeft: '10px'
                  }}
                  onChange={(e) => handleInputChange(e)}>
                  {associatedDepot &&
                    associatedDepot.length > 0 &&
                    associatedDepot.map((depot) => (
                      <MenuItem value={depot} key={depot}>
                        {depot}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* </MDBox> */}
          <Grid
            container
            spacing={1}
            style={{ paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px' }}
            justifyContent="center">
            <Grid xs={12} md={4} textAlign="center" item>
              <strong>{t('address')}: </strong> {parcData && parcData?.adresse}
            </Grid>
            <Grid xs={6} md={4} textAlign="center" item>
              <strong> {t('town')}: </strong> {parcData && parcData?.ville}
            </Grid>
            <Grid xs={6} md={4} textAlign="center" item>
              <strong> {t('country')}: </strong> {parcData && parcData?.pays}
            </Grid>

            <Grid xs={6} md={4} textAlign="center" item>
              <strong>{t('opening')}: </strong>
              {parcData &&
                formatTime(
                  parcData?.VBS_Schedule.opening_time_hour,
                  parcData?.VBS_Schedule.opening_time_minute,
                  parcData?.dateFormat
                )}
            </Grid>
            <Grid xs={6} md={4} textAlign="center" item>
              <strong> {t('closing')}: </strong>
              {parcData &&
                formatTime(
                  parcData?.VBS_Schedule.closing_time_hour,
                  parcData?.VBS_Schedule.closing_time_minute,
                  parcData?.dateFormat
                )}
            </Grid>
            <Grid xs={6} md={4} textAlign="center" item>
              <strong> {t('possibleGates')}: </strong>
              {parcData && parcData?.VBS_Schedule.gates_possible}
            </Grid>
          </Grid>
        </Card>
        {/* ) : null} */}
      </MDBox>

      {parcData && parcData.VBS ? (
        <>
          <ExternalViewSwitcher
            currentViewName={currentViewName}
            onChange={(e) => currentViewNameChange(e)}
            t={t}
          />

          <div
            style={
              (!parc
                ? disableScheduler
                : currentViewName === 'Month'
                ? disableMonthScheduler
                : null,
              {
                backgroundColor: 'white'
              })
            }>
            <Scheduler
              data={appointments}
              locale={localStorage.getItem('prefLanguage') === 'en' ? 'en-EN' : 'fr-FR'}
              // locale={parcData && parcData.dateFormat === 'US' ? 'en-EN' : 'fr-FR'}
            >
              <ViewState
                defaultCurrentDate={currentDate}
                currentViewName={currentViewName}
                onCurrentDateChange={currentDateChange}
              />
              <DayView
                startDayHour={(parcData && parcData.VBS_Schedule.opening_time_hour) || 8}
                endDayHour={(parcData && parcData.VBS_Schedule.closing_time_hour) || 12}
                cellDuration={parcData ? parseInt(parcData.VBS_Schedule.duration_rdv) : 30}
                timeTableCellComponent={timeTableCellComponent}
              />
              <WeekView
                name="Work Week"
                excludedDays={(parcData && parcData.VBS_Schedule.workingDays) || [0, 6]}
                startDayHour={(parcData && parcData.VBS_Schedule.opening_time_hour) || 8}
                endDayHour={(parcData && parcData.VBS_Schedule.closing_time_hour) || 12}
                cellDuration={parcData ? parseInt(parcData.VBS_Schedule.duration_rdv) : 30}
                timeTableCellComponent={timeTableCellComponent}
              />
              <MonthView />
              <Toolbar />
              <DateNavigator />

              <Appointments
                appointmentComponent={(props) => (
                  <CustomAppointement
                    props={props}
                    truckerDetails={truckerDetails}
                    setAppointementData={setAppointementData}
                    setVisibleDetails={setVisibleDetails}
                  />
                )}
                containerComponent={appointmentContent}
              />
            </Scheduler>{' '}
          </div>
        </>
      ) : (
        <Paper style={{ height: window.innerHeight / 2, marginBottom: '10px' }}>
          <Grid container>
            <Grid textAlign="center" xs={12} mt={25} item>
              {!parc && loading ? (
                t('PleaseSelectYard')
              ) : parc && loading ? (
                <div>
                  {' '}
                  <CircularProgress />
                </div>
              ) : parc && !loading && !parcData.VBS ? (
                <div> &#9888; {t('noMoreAppointment')} &#9888;</div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
      <MDBox style={{ fontSize: 15 }} mb={2}>
        <Card>
          <Grid container justifyContent="center">
            <Grid xs={3} md={3} textAlign="center" item>
              {t('coming')}
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              {t('completed')}
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              {t('rejected')}
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              {t('noshow')}
            </Grid>

            <Grid xs={3} md={3} textAlign="center" item>
              <CircleIcon style={{ color: '#2BE095' }} fontSize="large" />
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              <CircleIcon style={{ color: '#D2D2D2' }} fontSize="large" />
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              <CircleIcon style={{ color: '#B62000' }} fontSize="large" />
            </Grid>
            <Grid xs={3} md={3} textAlign="center" item>
              <CircleIcon style={{ color: 'orange' }} fontSize="large" />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <CustomDialog
        hiddenButtons={true}
        isVisible={visibleDetails}
        paragraph={
          <Grid container spacing={{ xs: 2, md: 2 }} padding={{ xs: 2, md: 8 }}>
            <Grid item xs={12} textAlign="center">
              <strong>{t('bookingReference')} : </strong>
              <span ref={bookingRef} style={{ marginRight: '10px' }}>
                {AppointmentData.trucker_booking}
              </span>
              <Tooltip title={t('Copy')}>
                <ContentCopyOutlinedIcon
                  fontSize="small"
                  onClick={copyToClipboard}></ContentCopyOutlinedIcon>
              </Tooltip>
            </Grid>
            {AppointmentData.opType === gateOpType.CONTAINER ||
            AppointmentData.opType === gateOpType.ONWHEELS ? (
              <>
                <Grid item xs={12} md={6}>
                  <strong>{t('conType')} : </strong>
                  {AppointmentData.conType}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>{t('conSize')} : </strong>
                  {AppointmentData.size}
                </Grid>
              </>
            ) : null}
            {AppointmentData.opType === gateOpType.CONTAINER ||
            AppointmentData.opType === gateOpType.ONWHEELS ? (
              <Grid item xs={12} md={12}>
                <strong>
                  {t('empty')}/{t('full')} :{' '}
                </strong>
                {AppointmentData.isFull ? t('full') : t('empty')}
              </Grid>
            ) : null}
            {AppointmentData.opType === gateOpType.ONWHEELS ||
            AppointmentData.opType === gateOpType.CHASSIS ? (
              <>
                <Grid item xs={12} md={6}>
                  <strong>{t('chassis_number')} : </strong>
                  {AppointmentData.chassisNumber}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>{t('chassisSize')} : </strong>
                  {AppointmentData.chassisSize}
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} md={6}>
              <strong>{t('status')} : </strong>{' '}
              {AppointmentData.status === status.PENDING
                ? `${t('coming')}`
                : AppointmentData.status === status.COMPLETED
                ? `${t('complete')}`
                : AppointmentData.status === status.NOSHOW
                ? `${t('noshow')}`
                : `${t('rejectedByDepot')}`}
            </Grid>

            <Grid item xs={12} md={6}>
              <strong>{t('gateType')} : </strong>{' '}
              {AppointmentData.action === gateType.drop_off ? 'Drop-off' : 'Pick-up'}
            </Grid>
            {AppointmentData.bookingId ? (
              <Grid item xs={12} md={6}>
                <strong>{t('bookingNumber')} : </strong> {AppointmentData.bookingId}
              </Grid>
            ) : null}

            <Grid item xs={12} md={6}>
              <strong>{t('Company')} : </strong> {AppointmentData.truckerCompany}
            </Grid>
            <Grid item xs={12} md={6}>
              <strong> {t('startDate')} :</strong>{' '}
              {moment(AppointmentData.startDate).format(
                parcData && parcData.dateFormat === 'US'
                  ? 'MM-DD-YYYY, hh:mm A'
                  : 'DD-MM-YYYY, HH:mm'
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <strong>{t('endDate')} : </strong>{' '}
              {moment(AppointmentData.endDate).format(
                parcData && parcData.dateFormat === 'US'
                  ? 'MM-DD-YYYY, hh:mm A'
                  : 'DD-MM-YYYY, HH:mm'
              )}
            </Grid>
            {AppointmentData.linerNote ? (
              <Grid item xs={12}>
                <strong>{t('linerNote')} : </strong> {AppointmentData.linerNote}
              </Grid>
            ) : null}
            {AppointmentData.rejectionNote ? (
              <Grid item xs={12}>
                <strong> {t('depotNote')} : </strong>
                <strong style={{ color: 'red' }}> {AppointmentData.rejectionNote}</strong>
              </Grid>
            ) : null}

            {AppointmentData.truckerName ? (
              <Grid item xs={12} md={6}>
                <strong>{t('driverName')} : </strong> {AppointmentData.truckerName}
              </Grid>
            ) : null}
            {AppointmentData.trucker_NID ? (
              <Grid item xs={12} md={6}>
                <strong>{t('identityCard')} : </strong> {AppointmentData.trucker_NID}
              </Grid>
            ) : null}
            <Grid container>
              <Grid xs={6} pt={5} textAlign="end" item>
                <Tooltip title={t('print')}>
                  <PrintIcon
                    fontSize="large"
                    color="action"
                    onClick={async () => {
                      try {
                        window.open(
                          URL.createObjectURL(
                            generatePdf(
                              AppointmentData,
                              parc,
                              truckerDetails,
                              t,
                              parcData.dateFormat
                            ).output('blob')
                          )
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xs={6} pt={5} textAlign="end" item>
                <Tooltip title={t('delete')}>
                  <DeleteForeverIcon
                    fontSize="large"
                    color="error"
                    onClick={async () => {
                      await deleteAppointment(parc, AppointmentData.trucker_booking);
                      setVisibleDetails(false);
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        }
        handleClose={handleClose}
        handleSubmit={handleClose}
        title={AppointmentData.title}
      />
      {visibleForm && (
        <CustomDialog
          isVisible={visibleForm}
          paragraph={AppoitementFormContent()}
          handleClose={handleCloseForm}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          reserved={reserved}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
  // );
};

const mapStateToProps = (state) => ({
  associatedDepot: state.auth.loggedUser.associatedDepot,
  parcData: state.vbs.parcData,
  managerMail: state.auth.loggedUser.email,
  truckerDetails: state.vbs.truckerDetails,
  isoCodes: state.vbs.isoCodes,
  appointments: state.vbs.appointments,
  allBookings: state.vbs.allBookings,
  allBookingsIds: state.vbs.allBookingsIds,
  loading: state.vbs.loading,
  schedulerRange: state.vbs.schedulerRange
});
export default connect(mapStateToProps, {
  getParcSetup,
  getTruckerDetails,
  addAppointment,
  getAllAppointment,
  getAllBookings,
  setDateRange,
  getIsoCodes
})(withTranslation()(Appointment));
