export const columns = [
  {
    field: "displayName",
    headerName: "Nom",
    width: 150,
  },

  {
    field: "email",
    headerName: "Email",
    width: 320,
  },
  {
    field: "phoneNumber",
    headerName: "Num. Tél",
    width: 180,
  },
  { field: "roles", headerName: "Rôles", width: 150 },
  { field: "authorizedReports", headerName: "Rapport affecté", width: 230 },
  { field: "associatedDepot", headerName: "Dépôt associé", width: 300 },
];
