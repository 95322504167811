import { combineReducers } from "redux";
import auth from "./auth";
import vbs from "./vbs";
import yard from "./yard";
import users from "./users";
const rootReducer = combineReducers({
  auth,
  vbs,
  yard,
  users,
});
export default rootReducer;
