import moment from "moment";

export const columnsEn = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 180,
    valueGetter: (params) => `${moment(params.row.createdAt).format('DD/MM/YYYY,hh:mm')} `
  },

  {
    field: 'companyName',
    headerName: 'Company name',
    width: 250
    //valueGetter: (params) => `${params.row.eirId || ""} `,
  },
  {
    field: 'taxId',
    headerName: 'Tax ID',
    width: 180
    //valueGetter: (params) => `${params.row.eirId || ""} `,
  },
  { field: 'pays', headerName: 'Country', width: 150 },
  { field: 'address', headerName: 'Address', width: 500 },
  {
    field: 'yard',
    headerName: 'Actif',
    width: 300,
    renderCell: (params) =>
      params.row.yard.map((yard) => (
        <div
          key={yard}
          style={{
            color: params.row?.actifVBSParcs?.includes(yard) ? '#006E00' : '#FF0000',
            fontWeight: params.row?.actifVBSParcs?.includes(yard) ? 'bold' : 'normal'
          }}>
          -{yard}
        </div>
      ))
  }
];
