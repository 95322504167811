import { useState, useEffect } from "react";

import i18n from "i18next";

export const storeData = async (key, value) => {
  localStorage.setItem(key, value);
};

export const getData = async (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
    return "en";
  } catch (e) {
    return "en";
  }
};

const usePrefLanguage = (value) => {
  const [pref, setPref] = useState(value);

  useEffect(() => {
    getData("prefLanguage").then((res) => {
      i18n.changeLanguage(res);
      setPref(res);
    });
  }, []);

  const changeLanguage = (value) => {
    storeData("prefLanguage", value).then(() => {
      i18n.changeLanguage(value);
      setPref(value);
    });
  };

  return [pref, changeLanguage];
};

export default usePrefLanguage;
