import { gateOpType } from 'config/config';
import { gateType } from 'config/config';
import * as jspdf from 'jspdf';
import moment from 'moment';
import shippzerLogo from '../assets/images/ShipzzerLogo.png';
import { generateQRCode } from './helpers';

export const generatePdf = (data, parcName, truckerDetails, t, dateFormat) => {
  const doc = new jspdf.default('p', 'mm', 'a4');
  const image = generateQRCode(data);
  /* blue and green rectangle decoration */
  doc.setFillColor(222, 235, 255);
  doc.rect(0, doc.internal.pageSize.getHeight() - 100, 70, 35, 'F');

  doc.setFillColor(227, 255, 240);
  doc.rect(50, doc.internal.pageSize.getHeight() - 50, 100, 35, 'F');
  /* trucker info */
  let isUsFormat1 = dateFormat === 'US' ? 'MM/DD/YYYY [hh:mm A' : 'DD/MM/YYYY [HH:mm';
  let isUsFormat2 = dateFormat === 'US' ? 'hh:mm A]' : 'HH:mm]';
  doc.addImage(shippzerLogo, 'PNG', 10, 11, 35, 3, undefined, 'FAST');
  doc.setFontSize(15);
  doc.setFont('Prompt', 'bold');
  doc.text(120, 15, truckerDetails.companyName);
  doc.setFont('Prompt', 'normal');

  doc.setFontSize(12);
  const longDesc = doc.splitTextToSize(truckerDetails.address, 60);
  doc.text(120, 20, longDesc);
  doc.text(120, 30, truckerDetails.pays);
  doc.text(120, 35, `${t('phone')} :`);
  doc.text(
    140,
    35,
    truckerDetails.contacts[0].tel.internationalNumber
      ? truckerDetails.contacts[0]?.tel.internationalNumber
      : truckerDetails.contacts[0].tel['number']
      ? truckerDetails.contacts[0]?.tel['number']
      : truckerDetails.contacts[0]?.tel['nationalNumber']
      ? truckerDetails.contacts[0]?.tel['nationalNumber']
      : 'N/A'
  );
  doc.text(120, 40, 'Email : ');
  doc.setTextColor('#083CA4');
  const textWidth = doc.getTextWidth(
    truckerDetails.contacts[0]?.email ? truckerDetails.contacts[0]?.email : 'N/A'
  );
  doc.line(140, 40.5, 140 + textWidth, 40.5);
  doc.text(140, 40, truckerDetails.contacts[0]?.email ? truckerDetails.contacts[0]?.email : 'N/A');
  doc.setTextColor(255, 255, 255);

  /* booking details */

  doc.setFillColor(55, 105, 200);
  doc.rect(5, 45, 200, 52, 'F');
  doc.setDrawColor(0);
  doc.setFontSize(14);

  doc.setFont('Prompt', 'bold');
  doc.text(70, 52, `${t('bookingConfirmation')}`);
  doc.setFont('Prompt', 'normal');

  doc.text(
    70,
    62,
    `Date : ${moment(data.startDate).format(isUsFormat1)} | ${moment(data.endDate).format(
      isUsFormat2
    )} `
  );
  doc.text(70, 72, `${t('depotName')} : ` + parcName);
  doc.text(70, 82, 'ID:  ' + data.trucker_booking);
  doc.text(70, 92, `${t('truckerplate')} : ` + data.plate);
  /* other details */
  doc.setTextColor('');

  doc.rect(5, 102, 200, 190);
  doc.setFontSize(12);
  doc.setFont('Prompt', 'bold');
  doc.text(30, 110, 'Type :');
  doc.text(30, 120, `${t('serialNumber')} : `);
  doc.text(30, 130, `${t('chassis_number')} : `);
  doc.text(30, 140, `${t('typeSize')} : `);
  doc.text(30, 150, `${t('liner')} : `);
  data.bookingId
    ? doc.text(30, 160, `${t('bookingNumber')} : `)
    : doc.text(30, 160, `${t('full')} / ${t('empty')} : `);
  data.bookingId ? doc.text(30, 170, `${t('full')} / ${t('empty')} :`) : null;
  data.truckerName ? doc.text(30, 260, `${t('driverName')} :`) : null;
  data.trucker_NID ? doc.text(30, 270, `${t('CIN')} :`) : null;
  doc.setFont('Prompt', 'normal');
  doc.text(80, 110, data.action === gateType.drop_off ? 'GATE IN' : 'GATE OUT');
  doc.text(80, 120, data.serialNumber);
  doc.text(80, 130, data.chassisNumber);
  data.opType === gateOpType.CHASSIS
    ? doc.text(80, 140, `${t('Chassis')} / ${data.chassisSize}`)
    : data.opType === gateOpType.ONWHEELS
    ? doc.text(80, 140, `${data.size} / ${data.conType} | ${t('Chassis')} / ${data.chassisSize}`)
    : doc.text(80, 140, `${data.size} / ${data.conType}`);
  doc.text(80, 150, `${data.armateur}`);
  data.bookingId
    ? doc.text(80, 160, data.bookingId)
    : data.opType !== gateOpType.CHASSIS
    ? doc.text(80, 160, data.isFull ? `${t('full')}` : `${t('empty')}`)
    : null;
  data.bookingId ? doc.text(80, 170, data.isFull ? `${t('full')}` : `${t('empty')}`) : null;
  data.truckerName ? doc.rect(25, 250, 100, 30) : null;

  data.truckerName ? doc.text(80, 260, data.truckerName) : null;
  data.trucker_NID ? doc.text(80, 270, data.trucker_NID) : null;
  /* QR CODE */
  doc.addImage(image, 'PNG', 78, 173, 65, 65, undefined, 'FAST');
  /* Bottom rectangle */
  doc.rect(145, 250, 55, 30);
  doc.setFontSize(9);
  doc.text(148, 254, `${t('signature')}`);
  return doc;
};
