// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withTranslation } from "react-i18next";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  OutlinedInput,
  Chip,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { columns } from "./tableHeader";
import { DataGrid, frFR, enUS, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import CustomDialog from 'components/CustomComponent/CustomDialog/CustomDialog';
import { getTransportersByCountry } from 'actions/yard';
import 'react-phone-input-2/lib/style.css';
import { getVbsYards } from 'actions/yard';
import { addTransporter } from 'actions/yard';
import i18next from 'i18next';
import { columnsEn } from './tableHeaderEN';
const TruckerCompanies = ({
  t,
  allYards,
  getTransportersByCountry,
  transporters,
  getVbsYards,
  addTransporter
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const [pageSize, setPageSize] = useState(10);
  const [parcNames, setParcNames] = useState([]);
  // eslint-disable-next-line
  const [assignedParc, setAssignedParcs] = useState([]);
  // eslint-disable-next-line
  const [truckerInfo, setTruckerInfo] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    getTransportersByCountry();
  }, []);
  useEffect(() => {
    getVbsYards();
  }, []);
  const handleClose = () => {
    setIsVisible(false);
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === 'parcNames') {
      const {
        target: { value }
      } = e;
      setParcNames(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const reinitForm = () => {
    setParcNames([]);
  };
  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1 === object2;
      });
    });
  };
  const handleSubmit = () => {
    setIsVisible(false);
    const diff = [...getDifference(parcNames, assignedParc)];

    truckerInfo.createdBy = 'adminVBS';
    if (diff.length > 0) {
      addTransporter(diff, truckerInfo);
    }
    reinitForm();
  };
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0
        }}>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {t('truckerCompanyPerCountry')}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid
                  localeText={
                    ({
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `${from} - ${to} ${i18next.language === 'fr' ? 'sur' : 'of'} ${count}`,
                        labelRowsPerPage:
                          i18next.language === 'fr' ? 'Lignes par page' : 'Rows per page'
                      }
                    },
                    i18next.language === 'fr'
                      ? frFR.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText)
                  }
                  rows={transporters || []}
                  columns={i18next.language === 'fr' ? columns : columnsEn}
                  getRowId={(row) => row.createdAt}
                  rowsPerPageOptions={[10, 20, 50]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                  disableColumnSelector
                  autoHeight
                  components={{
                    Toolbar: QuickSearchToolbar
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomDialog
        isVisible={isVisible}
        title={'Affecter transporteur à un depôt'}
        paragraph={
          <>
            <Grid container>
              <Grid sm={12} p={2} item>
                <h4 style={{ color: '#257eea', textAlign: 'center' }}>{t('associatedDepots')}</h4>

                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">{t('yard')}</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    name="parcNames"
                    multiple
                    value={parcNames}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250
                        }
                      }
                    }}
                    style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}>
                    {allYards.map((depot) => (
                      <MenuItem key={depot} value={depot}>
                        {depot}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <Footer />
    </DashboardLayout>
  );
};
const mapStateToProps = (state) => ({
  loading: state.vbs.loading,
  allYards: state.yard.vbsYards,
  transporters: state.yard.transporters,
});

export default connect(mapStateToProps, { getTransportersByCountry, getVbsYards, addTransporter })(
  withTranslation()(TruckerCompanies)
);
