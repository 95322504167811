import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';

const CustomDialog = ({
  paragraph,
  isVisible,
  handleClose,
  handleSubmit,
  title = '',
  submitLabel = 'confirm',
  cancelLabel = 'cancel',
  hiddenButtons = false,
  t,
  isSubmitting = false,
  reserved
}) => (
  <Dialog
    open={isVisible}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="md">
    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
      {title}
    </DialogTitle>
    <DialogContent>
      <>{paragraph}</>
    </DialogContent>
    {!hiddenButtons ? (
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          {t(cancelLabel)}
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting || reserved} autoFocus>
          {t(submitLabel)}
        </Button>
      </DialogActions>
    ) : null}
  </Dialog>
);

export default withTranslation()(CustomDialog);
