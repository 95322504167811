import axios from 'axios';
import { gateOpType, gateType, status, URL } from '../config/config';
import { toast } from 'react-toastify';
import { db, getHeaders } from '../config/firebase';
import {
  GET_PARC_SETUP,
  GET_TRUCKERS,
  GET_TRUCKERS_ERROR,
  REINIT,
  ADD_PLATE,
  ADD_CDL,
  ADD_CDLANDNAME,
  GET_EIR_HISTORY,
  ADD_APPOINTMENT,
  GET_ALL_APPOINTEMENT,
  GET_ALL_BOOKINGS,
  GET_PARC_COUNTER,
  GET_ACTIF_TRUCKERS_NUMBER,
  GET_DATE_RANGE,
  ISOCODES,
  DELETE_PLATE,
  DELETE_DRIVER
} from './types';
import { generateIdBooking } from 'helpers/helpers';
import moment from 'moment-timezone';
import { firestore } from 'firebase';
import i18next from 'i18next';
import { startOfDay, startOfWeek, startOfMonth } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';

/** GET ALL ISOCODE FROM FB */
export const getIsoCodes = () => async (dispatch) => {
  try {
    const isoCodes = await db.collection('constants').doc('isoCodes').get();

    // const { isoCode } = isoCodes.data();

    dispatch({
      type: ISOCODES,
      payload: { isoCode: isoCodes.data() }
    });
    return isoCodes.data();
  } catch (error) {
    console.log('error in getIsoCodes', error);
  }
};

export const getParcSetup = (parcId) => async (dispatch) => {
  try {
    const parcData = await db.collection(`yards`).doc(parcId).get();
    const {
      mostUsedIsoCode,
      VBS_Schedule,
      pays,
      ville,
      adresse,
      shipOwnerClient,
      filesIdPrefix,
      VBS,
      timezone,
      dateFormat
    } = parcData.data();
    dispatch({
      type: GET_PARC_SETUP,
      payload: {
        mostUsedIsoCode,
        VBS_Schedule,
        shipOwnerClient,
        pays,
        ville,
        adresse,
        filesIdPrefix,
        VBS,
        timezone,
        dateFormat
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getTruckerDetails = (parcId, managerMail) => async (dispatch) => {
  try {
    const truckerList = await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .where('managerVbs', 'array-contains', managerMail)
      .get();
    dispatch({
      type: GET_TRUCKERS,
      payload: truckerList.docs[0].data()
    });
  } catch (error) {
    toast.error('erreur survenue merci de contacter le support ');
    dispatch({
      type: GET_TRUCKERS_ERROR
    });
    console.log(error);
  }
};

export const addLicensePlate = (parcId, companyName, transporterPlates) => async (dispatch) => {
  try {
    await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .update(transporterPlates);
    dispatch({
      type: ADD_PLATE,
      payload: transporterPlates
    });
    toast.success(i18next.language === 'fr' ? 'Plaque enregistrée' : 'Plate registered');
  } catch (error) {
    toast.error('erreur survenue merci de contacter le support ');
    console.log(error);
  }
};

export const deletePlate = (parcId, companyName, plate) => async (dispatch) => {
  try {
    const allPlates = await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .get();

    const newTrucks = {
      trucks: allPlates.data().trucks.filter((el) => el !== plate)
    };
    await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .update(newTrucks);
    console.log(
      'allPlates',
      allPlates.data().trucks.filter((el) => el !== plate)
    );

    dispatch({
      type: DELETE_PLATE,
      payload: newTrucks
    });
    toast.success(i18next.language === 'fr' ? 'Plaque supprimée' : 'Plate deleted');
  } catch (error) {
    console.log(error);
  }
};

export const addCDL = (parcId, companyName, cins) => async (dispatch) => {
  try {
    await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .update(cins);
    dispatch({
      type: ADD_CDL,
      payload: cins
    });
  } catch (error) {
    toast.error('erreur survenue merci de contacter le support ');
    console.log(error);
  }
};

export const getEirHistory = (formData) => async (dispatch) => {
  try {
    if (formData.parc === 'ALL') {
      formData.parc = formData.associatedDepot;
    } else {
      formData.parc = [formData.parc];
    }
    delete formData.associatedDepot;
    const headers = await getHeaders();
    const response = await axios.post(`${URL.API_URL}/eirHistory`, formData, { headers: headers });
    dispatch({
      type: GET_EIR_HISTORY,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const addAppointment = (formData, timezone) => async (dispatch) => {
  try {
    const { parc } = formData;
    const batch = db.batch();
    const trucker_booking = generateIdBooking(formData.parcPrefix);

    const timeZoneUtcOffsetParc = moment.tz.zone(timezone).utcOffset(Date.now()) / 60;
    const localTimezone = moment.tz.guess();
    const timeZoneUtcOffsetLocal = moment.tz.zone(localTimezone).utcOffset(Date.now()) / 60;
    const timeZoneLocalToParcOffset = timeZoneUtcOffsetParc - timeZoneUtcOffsetLocal;

    formData.startDate = moment(formData.startDate).add(timeZoneLocalToParcOffset, 'h').valueOf();
    formData.endDate = moment(formData.endDate).add(timeZoneLocalToParcOffset, 'h').valueOf();
    formData.createdAt = Date.now();
    formData.serialNumber =
      formData.serialNumber.length > 0 ? formData.serialNumber.toUpperCase() : '';
    formData.chassisNumber =
      formData.chassisNumber.length > 0 ? formData.chassisNumber.toUpperCase() : '';
    const appoitmentData = db
      .collection('yards')
      .doc(parc)
      .collection('truckerBookings')
      .doc(trucker_booking);
    batch.set(appoitmentData, { ...formData, trucker_booking });
    const countersRef = db
      .collection('yards')
      .doc(parc)
      .collection('counters')
      .doc('truckerBooking');

    const company = await countersRef
      .collection(formData.truckerCompany)
      .doc('accumulatedBookings')
      .get();
    const companyRef = countersRef.collection(formData.truckerCompany).doc('accumulatedBookings');
    if (company.exists) {
      batch.update(companyRef, {
        counterValue: firestore.FieldValue.increment(1)
      });
    } else {
      batch.set(companyRef, { counterValue: 1 });
    }

    await batch.commit();

    dispatch({
      type: ADD_APPOINTMENT
    });

    toast.success(
      i18next.language === 'fr' ? 'RDV enregistré avec succès' : 'Appointment registered'
    );
    return trucker_booking;
  } catch (error) {
    toast.error(
      i18next.language === 'fr'
        ? 'Erreur survenue merci de contacter le support'
        : 'Error, please contact support'
    );
    console.log(error);
  }
};

/**Add driver's info and dispatch them */
export const addTuckerInfo = (parcId, companyName, cdlAndName) => async (dispatch) => {
  try {
    const cdlAndNameRef = await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .get();

    if (cdlAndNameRef.data().cdlAndName) {
      cdlAndNameRef.ref.update(cdlAndName);
      // console.log('***********exists', cdlAndName);
    } else {
      cdlAndNameRef.ref.set(cdlAndName);
      // console.log("##########doesn't exist", cdlAndName);
    }
    dispatch({
      type: ADD_CDLANDNAME,
      payload: cdlAndName
    });
    toast.success(
      i18next.language === 'fr'
        ? 'Les informations du chauffeur ont été enregistrées avec succès'
        : `Driver information successfully registered`
    );
  } catch (error) {
    toast.error('erreur survenue merci de contacter le support ');
    console.log(error);
  }
};

/** Delete a driver  */

export const deleteDriver = (parcId, companyName, driver) => async (dispatch) => {
  try {
    const allDrivers = await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .get();
    const newDrivers = {
      cdlAndName: allDrivers.data().cdlAndName.filter((el) => el.cdl !== driver)
    };
    await db
      .collection('yards')
      .doc(parcId)
      .collection('fournisseurs')
      .doc(companyName)
      .update(newDrivers);

    dispatch({
      type: DELETE_DRIVER,
      payload: newDrivers
    });
    toast.success(i18next.language === 'fr' ? 'Chauffeur supprimé' : 'Driver deleted');
  } catch (error) {
    console.log(error);
  }
};

export const getAllAppointment = (parc, timezone, schedulerRange) => (dispatch) => {
  try {
    if (parc) {
      const timeZoneUtcOffsetParc = moment.tz.zone(timezone).utcOffset(Date.now()) / 60;
      const localTimezone = moment.tz.guess();
      const timeZoneUtcOffsetLocal = moment.tz.zone(localTimezone).utcOffset(Date.now()) / 60;
      const timeZoneParcToLocalOffset = timeZoneUtcOffsetLocal - timeZoneUtcOffsetParc;

      const unsubscribe = db
        .collection('yards')
        .doc(parc)
        .collection('truckerBookings')
        .where('startDate', '>=', schedulerRange.startDate)
        .onSnapshot((appointmentsSnapshot) => {
          let appointmentsList = [];
          if (!appointmentsSnapshot.empty) {
            appointmentsSnapshot.docs.map((doc) => {
              if (doc.data().endDate <= schedulerRange.endDate) {
                const {
                  opType,
                  chassisNumber,
                  serialNumber,
                  truckerCompany,
                  size,
                  plate,
                  chassisSize,
                  startDate,
                  endDate,
                  status,
                  action,
                  trucker_booking,
                  bookingId,
                  truckerName,
                  trucker_NID,
                  rejectionNote,
                  linerNote,
                  conType,
                  client,
                  isFull,
                  armateur
                } = doc.data();
                appointmentsList.push({
                  title: `${
                    (opType === gateOpType.CONTAINER && action === gateType.drop_off) ||
                    (opType === gateOpType.ONWHEELS && action === gateType.drop_off)
                      ? serialNumber
                      : opType === gateOpType.CHASSIS && action === gateType.drop_off
                      ? chassisNumber
                      : 'PICK UP'
                  } - ${
                    opType === gateOpType.CONTAINER
                      ? conType
                      : opType === gateOpType.ONWHEELS
                      ? 'On wheels'
                      : 'Chassis'
                  } - ${
                    opType === gateOpType.CONTAINER || opType === gateOpType.ONWHEELS
                      ? size
                      : chassisSize
                  } - ${plate}`,
                  startDate: moment(new Date(startDate))
                    .add(timeZoneParcToLocalOffset, 'h')
                    .format(),
                  endDate: moment(new Date(endDate)).add(timeZoneParcToLocalOffset, 'h').format(),
                  status: status,
                  action: action,
                  trucker_booking: trucker_booking,
                  bookingId: bookingId,
                  truckerName: truckerName,
                  trucker_NID: trucker_NID,
                  rejectionNote: rejectionNote ? rejectionNote : '',
                  linerNote: linerNote ? linerNote : '',
                  conType: conType ? conType : '',
                  size: size ? size : '',
                  truckerCompany: truckerCompany ? truckerCompany : '',
                  plate: plate ? plate : '',
                  serialNumber: serialNumber ? serialNumber : '',
                  client: client ? client : '',
                  armateur: armateur ? armateur : '',
                  isFull: isFull ? isFull : '',
                  opType: opType,
                  chassisNumber: chassisNumber,
                  chassisSize: chassisSize
                });
              }
            });
          }

          // TODO persist appointmetsList to state

          dispatch({
            type: GET_ALL_APPOINTEMENT,
            payload: appointmentsList
          });
        });

      // TODO unsubscribe() when unmounting component
      return unsubscribe;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCouplets = async (input, parc) => {
  try {
    const array = [];
    const data = await db
      .collection('yards')
      .doc(parc)
      .collection('booking')
      .doc(input.id)
      .collection('couplet')
      .get();
  
    data.docs.forEach((el) => {
      array.push({ ...el.data(), id: el.id });
    });
    return array;
  } catch (error) {
   console.log("error when getCouplets" ,error); 
  }

};
export const getAllBookings = (parc) => async (dispatch) => {
  try {
    let arrBookings = [];
    let arrBookingsId = [];
    const allBookings = await db.collection('yards').doc(parc).collection('booking').get();
    allBookings.docs.forEach(async (doc) => {
      const couplet = await getCouplets(doc, parc);
      arrBookings.push({ ...doc.data(), id: doc.id, couplet });
      arrBookingsId.push(doc.id);
    });
    dispatch({
      type: GET_ALL_BOOKINGS,
      payload: { all: arrBookings, allIds: arrBookingsId }
    });
  } catch (error) {
    console.log(error);
  }
};
export const totalBooking = (parc, companyName) => async (dispatch) => {
  try {
    const parallelPromises = [];
    let acceptedCounter = 0;
    let rejectedCounter = 0;
    let pendingCounter = 0;
    let noshowCounter = 0;
    const counter = await db
      .collection('yards')
      .doc(parc)
      .collection('counters')
      .doc('truckerBooking')
      .collection(companyName)
      .doc('accumulatedBookings')
      .get();
    const statusCounter = await db
      .collection('yards')
      .doc(parc)
      .collection('truckerBookings')
      .where('truckerCompany', '==', companyName)
      .get();
    if (counter.exists) {
      parallelPromises.push(counter.data());
    } else {
      parallelPromises.push({ counterValue: 0 });
    }

    statusCounter.docs.map((doc) => {
      if (doc.data().status === status.COMPLETED) acceptedCounter += 1;
      if (doc.data().status === status.REJECTED) rejectedCounter += 1;
      if (doc.data().status === status.PENDING) pendingCounter += 1;
      if (doc.data().status === status.NOSHOW) noshowCounter += 1;
    });
    parallelPromises.push({
      acceptedCounter: acceptedCounter,
      rejectedCounter: rejectedCounter,
      pendingCounter: pendingCounter,
      noshowCounter: noshowCounter
    });
    const promises = await Promise.all(parallelPromises);
    dispatch({
      type: GET_PARC_COUNTER,
      payload: promises
    });
  } catch (error) {
    console.log(error);
  }
};
export const getTruckersNumberWithBooking = (parc, companyName) => async (dispatch) => {
  const truckerBookingCounter = await db
    .collection('yards')
    .doc(parc)
    .collection('truckerBookings')
    .where('status', '==', status.PENDING)
    .where('truckerCompany', '==', companyName)
    .get();
  let allPlates = [];
  let uniquePlates = [];

  truckerBookingCounter.docs.map((doc) => {
    return allPlates.push(doc.data().plate);
  });

  uniquePlates = [...new Set(allPlates)];
  dispatch({
    type: GET_ACTIF_TRUCKERS_NUMBER,
    payload: uniquePlates.length
  });
};

export const deleteAppointment = async (parcId, refBooking) => {
  try {
    if (
      window.confirm(
        i18next.language === 'fr'
          ? 'Confirmez-vous la suppression de ce RDV ?'
          : 'Please confirm appointment removal'
      )
    ) {
      let reservedBooking = {};
      let restCouplet = [];
      let selectedBooking;
      const booking = await db
        .collection('yards')
        .doc(parcId)
        .collection('truckerBookings')
        .where('trucker_booking', '==', refBooking)
        .get();
      const { bookingId, serialNumber, conType, size, linerNote, chassisNumber } =
        booking.docs[0].data();

      if (bookingId && bookingId !== '') {
        selectedBooking = await db
          .collection('yards')
          .doc(parcId)
          .collection('booking')
          .doc(bookingId)
          .get();
      }

      if (!selectedBooking || !selectedBooking.exists) {
        booking.docs[0].ref.delete();
      } else {
        const { couplet } = selectedBooking.data();
        for (let index = 0; index < couplet.length; index++) {
          if (couplet[index].numChassis && couplet[index].numChassis.length) {
            if (couplet[index].numChassis === chassisNumber) {
              delete couplet[index].reserved;
              couplet[index].truckerBooking = couplet[index].truckerBooking.filter(
                (booking) => booking !== refBooking
              );
              restCouplet.push(couplet[index]);
            } else {
              restCouplet.push(couplet[index]);
            }
          } else {
            if (couplet[index].serialNumber === serialNumber) {
              delete couplet[index].reserved;
              couplet[index].truckerBooking = couplet[index].truckerBooking.filter(
                (booking) => booking !== refBooking
              );
              restCouplet.push(couplet[index]);
            } else if (
              couplet[index].conType === conType &&
              couplet[index].conSize === size &&
              couplet[index].complimentaryInfo.generalComments === linerNote
            ) {
              delete couplet[index].reserved;
              couplet[index].truckerBooking = couplet[index].truckerBooking.filter(
                (booking) => booking !== refBooking
              );
              restCouplet.push(couplet[index]);
            } else if (couplet[index].conType === conType && couplet[index].conSize === size) {
              delete couplet[index].reserved;
              couplet[index].truckerBooking = couplet[index].truckerBooking.filter(
                (booking) => booking !== refBooking
              );
              restCouplet.push(couplet[index]);
            } else {
              restCouplet.push(couplet[index]);
            }
          }
        }
        reservedBooking = {
          ...selectedBooking.data(),
          couplet: restCouplet
        };

        await db
          .collection('yards')
          .doc(parcId)
          .collection('booking')
          .doc(selectedBooking.id)
          .update(reservedBooking);
        booking.docs[0].ref.delete();
      }

      toast.success(i18next.language === 'fr' ? 'Suppression avec succès' : 'Deleted successfully');
    }
  } catch (error) {
    toast.error(
      i18next.language === 'fr'
        ? 'Erreur survenue merci de contacter le support'
        : 'Error occurred please contact support'
    );
    console.log(error);
  }
};
export const checkContainer = async (parcId, serialNumber) => {
  const container = await db
    .collection('yards')
    .doc(parcId)
    .collection('containers')
    .doc(serialNumber)
    .get();
  if (container.exists) {
    const { codeIso, emptyFull, onHold, numPlomb } = container.data();
    return {
      exists: container.exists,
      isoCode: codeIso,
      isFull: emptyFull,
      onHold: onHold,
      sealNumber: numPlomb
    };
  } else return { exists: container.exists };
};
export const reinit = () => (dispatch) => {
  dispatch({
    type: REINIT
  });
};
export const updateBooking = async (
  parcId,
  selectedBooking,
  bookingIndex,
  appointments,
  trucker_booking
) => {
  try {
    let restCouplet = [];
    for (let index = 0; index < selectedBooking.couplet.length; index++) {
      if (index !== bookingIndex - 1) {
        restCouplet.push(selectedBooking.couplet[index]);
      }
    }
    const coupletCollectionRef = db
      .collection('yards')
      .doc(parcId)
      .collection('booking')
      .doc(selectedBooking.id)
      .collection('couplet');

    if (selectedBooking.couplet[bookingIndex - 1].occurrence === 1) {
      await coupletCollectionRef.doc(selectedBooking.couplet[bookingIndex - 1].id).update({
        truckerBooking: selectedBooking.couplet[bookingIndex - 1].truckerBooking
          ? [...selectedBooking.couplet[bookingIndex - 1].truckerBooking, trucker_booking]
          : [trucker_booking]
      });
    } else {
      if (
        selectedBooking.couplet[bookingIndex - 1].occurrence ===
        selectedBooking.couplet[bookingIndex - 1].truckerBooking.length + 1
      ) {
        await coupletCollectionRef.doc(selectedBooking.couplet[bookingIndex - 1].id).update({
          truckerBooking: selectedBooking.couplet[bookingIndex - 1].truckerBooking
            ? [...selectedBooking.couplet[bookingIndex - 1].truckerBooking, trucker_booking]
            : [trucker_booking],
          reserved: true
        });
      } else {
        await coupletCollectionRef.doc(selectedBooking.couplet[bookingIndex - 1].id).update({
          truckerBooking: selectedBooking.couplet[bookingIndex - 1].truckerBooking
            ? [...selectedBooking.couplet[bookingIndex - 1].truckerBooking, trucker_booking]
            : [trucker_booking]
        });
      }
    }
  } catch (error) {
    console.log('error in function updateBooking', error);
  }
};

export const setDateRange = (schedulerDate, dateView) => (dispatch) => {
  try {
    let payload = {
      startDate: startOfWeek(Date.now(), { weekStartsOn: 1 }),
      endDate: endOfWeek(Date.now(), { weekStartsOn: 1 })
    };
    if (dateView === 'Day') {
      payload.startDate = startOfDay(schedulerDate);
      payload.endDate = endOfDay(schedulerDate);
    }

    if (dateView === 'Work Week') {
      payload.startDate = startOfWeek(schedulerDate, { weekStartsOn: 1 });
      payload.endDate = endOfWeek(schedulerDate, { weekStartsOn: 1 });
    }

    if (dateView === 'Month') {
      payload.startDate = startOfMonth(schedulerDate);
      payload.endDate = endOfMonth(schedulerDate);
    }
    payload.startDate = Math.floor(payload.startDate);
    payload.endDate = Math.floor(payload.endDate);
    dispatch({
      type: GET_DATE_RANGE,
      payload: payload
    });
  } catch (error) {
    console.log(error);
  }
};
export const checkChassis = async (parcId, serialNumber) => {
  try {
    if (serialNumber.length) {
      const container = await db
        .collection('yards')
        .doc(parcId)
        .collection('chassis')
        .doc(serialNumber)
        .get();
      return { exists: container.exists };
    } else return false;
  } catch (error) {
    console.log(error);
  }
};
