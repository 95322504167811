import axios from "axios";
import { HEADERS } from "./config";
import { auth } from "./firebase";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;
export const getHeaders = () =>
  new Promise((resolve, reject) => {
    auth.currentUser
      .getIdToken(true)
      .then((idToken) => resolve({ ...HEADERS, Authorization: idToken }))
      .catch((error) => reject(error));
  });
