// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

// Dashboard components

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getParcSetup,
  totalBooking,
  getTruckerDetails,
  getTruckersNumberWithBooking
} from 'actions/vbs';
import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import i18n from 'i18next';

const Dashboard = ({
  t,
  associatedDepot,
  bookingCounter,
  actifTruckerNumber,
  getTruckersNumberWithBooking,
  totalBooking,
  getTruckerDetails,
  managerMail,
  truckerDetails,
  companyName
}) => {
  const [bookingStatus, setBookingStatus] = useState({
    labels: [t('completed'), t('coming'), t('rejected'), t('noshow')],
    datasets: { label: ' Statut booking', data: [] }
  });
  const [parcId, setParcId] = useState(localStorage.getItem('parcId'));
  useEffect(() => {
    if (associatedDepot && associatedDepot.length > 0) {
      getTruckerDetails(parcId, managerMail);
      getTruckersNumberWithBooking(parcId, companyName);
      totalBooking(associatedDepot && parcId, companyName);
    }
  }, [associatedDepot, parcId]);

  useEffect(() => {
    if (bookingCounter && bookingCounter.length > 0) {
      // if (i18n.language === 'en') {
      setBookingStatus({
        labels: [t('completed'), t('coming'), t('rejected'), t('noshow')],
        datasets: {
          label: t('bookingStatus'),
          data: [
            bookingCounter && bookingCounter[1].acceptedCounter,
            bookingCounter && bookingCounter[1].pendingCounter,
            bookingCounter && bookingCounter[1].rejectedCounter,
            bookingCounter && bookingCounter[1].noshowCounter
          ]
        }
      });
      // }
      // if (i18n.language === 'fr') {
      //   setBookingStatus({
      //     labels: [t('completed'), t('coming'), t('rejected'), t('noshow')],
      //     datasets: {
      //       label: t('bookingStatus'),
      //       data: [
      //         bookingCounter && bookingCounter[1].acceptedCounter,
      //         bookingCounter && bookingCounter[1].pendingCounter,
      //         bookingCounter && bookingCounter[1].rejectedCounter,
      //         bookingCounter && bookingCounter[1].noshowCounter
      //       ]
      //     }
      //   });
      // }
    }
  }, [bookingCounter, i18n.language]);

  const handleDepotChange = (e) => {
    setParcId(e.target.value);
    localStorage.setItem('parcId', e.target.value);
    totalBooking(e.target.value, companyName);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid pb={5} container>
        <Grid xs={4} md={4} item></Grid>
        <Grid xs={12} md={6} item>
          <FormControl fullWidth>
            <InputLabel>{t('depot')}</InputLabel>
            <Select
              value={parcId}
              label="depot"
              name={t('depot')}
              style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}
              onChange={(e) => handleDepotChange(e)}>
              {associatedDepot &&
                associatedDepot.length > 0 &&
                associatedDepot.map((depot) => (
                  <MenuItem value={depot} key={depot}>
                    {depot}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="trending_up"
                title={t('cumulatedBookingPerYard')}
                count={
                  bookingCounter && bookingCounter.length > 0 && bookingCounter[0].counterValue
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="dark"
                icon="local_shipping"
                title={t('savedTrucker')}
                count={(truckerDetails && truckerDetails.trucks?.length) || '0'}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="dark"
                icon="schedule"
                title={t('truckerNumber')}
                count={actifTruckerNumber && actifTruckerNumber}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} alignContent="center">
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title={t('bookingCountPerStatus')}
                  chart={bookingStatus}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
         */}

        {/*  <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Bookings"
                  count={281}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Today's Users"
                  count="2,300"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Revenue"
                  count="34k"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Followers"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid> 
        </MDBox>*/}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
const mapStateToProps = (state) => ({
  truckerDetails: state.vbs.truckerDetails,
  associatedDepot: state.auth.loggedUser.associatedDepot,
  bookingCounter: state.vbs.bookingCounter,
  managerMail: state.auth.loggedUser.email,
  companyName: state.auth.loggedUser.companyName,
  actifTruckerNumber: state.vbs.actifTruckerNumber
});
export default connect(mapStateToProps, {
  getParcSetup,
  totalBooking,
  getTruckerDetails,
  getTruckersNumberWithBooking
})(withTranslation()(Dashboard));
