import { useState } from 'react';
// react-router-dom components
import { Navigate } from 'react-router-dom';
// @mui material components
import Card from '@mui/material/Card';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import Shipyard from 'assets/images/Shipyard-bg.jpg';
import ShipLogo from 'assets/images/ShipzzerLogo.png';
import { login } from 'actions/auth';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const Basic = ({ login, isAuthenticated, t }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const { email, password } = formData;
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    login(email, password);
  };
  //redirect if logged in
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <BasicLayout image={Shipyard}>
      <Card>
        <img src={ShipLogo} style={{ padding: '25px', marginTop: '15px' }} />

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={email}
                name="email"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t('Password')}
                value={password}
                name="password"
                onChange={(e) => handleInputChange(e)}
                fullWidth
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { login })(withTranslation()(Basic));
