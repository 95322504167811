export const columnsEn = [
  {
    field: "displayName",
    headerName: "Name",
    width: 150,
  },

  {
    field: "email",
    headerName: "Email",
    width: 320,
  },
  {
    field: "phoneNumber",
    headerName: "Phone number",
    width: 180,
  },
  { field: "roles", headerName: "Roles", width: 150 },
  { field: "authorizedReports", headerName: "Authorized reports", width: 230 },
  { field: "associatedDepot", headerName: "Associated depots", width: 300 },
];
