import { GET_DATE_RANGE } from 'actions/types';
import { LOGOUT } from 'actions/types';
import {
  GET_TRUCKERS,
  GET_PARC_SETUP,
  REINIT,
  ADD_PLATE,
  ADD_CDL,
  ADD_CDLANDNAME,
  GET_EIR_HISTORY,
  ADD_APPOINTMENT,
  GET_ALL_APPOINTEMENT,
  GET_TRUCKERS_ERROR,
  GET_ALL_BOOKINGS,
  GET_PARC_COUNTER,
  GET_ACTIF_TRUCKERS_NUMBER,
  ISOCODES,
  DELETE_PLATE,
  DELETE_DRIVER
} from 'actions/types';

const initialState = {
  parcData: null,
  truckerDetails: {},
  eirHistory: [],
  appointments: [],
  allBookings: [],
  allBookingsIds: [],
  bookingCounter: null,
  actifTruckerNumber: 0,
  isoCodes: {},
  loading: true,
  schedulerRange: {
    startDate: null,
    endDate: null
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PARC_SETUP:
      return {
        ...state,
        parcData: payload,
        loading: false
      };
    case ISOCODES:
      return {
        ...state,
        isoCodes: payload
      };
    case GET_TRUCKERS:
      return {
        ...state,
        truckerDetails: payload
      };
    case GET_TRUCKERS_ERROR:
      return { ...state, truckerDetails: {} };
    case ADD_PLATE:
      return {
        ...state,
        truckerDetails: { ...state.truckerDetails, ...(state.truckerDetails.trucks = payload) }
      };
    case DELETE_PLATE:
      return {
        ...state,
        truckerDetails: { ...state.truckerDetails, ...(state.truckerDetails.trucks = payload) }
      };
    case ADD_CDL:
      return {
        ...state,
        truckerDetails: { ...state.truckerDetails, ...(state.truckerDetails.cins = payload) }
      };
    case ADD_CDLANDNAME:
      return {
        ...state,
        truckerDetails: {
          ...state.truckerDetails,
          ...(state.truckerDetails.cdlAndName = payload)
        }
      };
    case DELETE_DRIVER:
      return {
        ...state,
        truckerDetails: {
          ...state.truckerDetails,
          ...(state.truckerDetails.cdlAndName = payload)
        }
      };
    case GET_EIR_HISTORY:
      return {
        ...state,
        eirHistory: payload
      };

    case ADD_APPOINTMENT:
      return {
        ...state
      };
    case GET_ALL_APPOINTEMENT:
      return {
        ...state,
        appointments: payload
      };
    case GET_ALL_BOOKINGS:
      return {
        ...state,
        allBookings: payload.all,
        allBookingsIds: payload.allIds
      };

    case GET_PARC_COUNTER:
      return {
        ...state,
        bookingCounter: payload
      };
    case GET_ACTIF_TRUCKERS_NUMBER:
      return {
        ...state,
        actifTruckerNumber: payload
      };
    case GET_DATE_RANGE:
      return {
        ...state,
        schedulerRange: payload
      };
    case REINIT:
      return {
        ...state,
        isoCodes: {},
        truckerDetails: {},
        eirHistory: [],
        appointments: [],
        allBookings: [],
        allBookingsIds: [],
        parcData: null,
        bookingCounter: null,
        loading: true,
        schedulerRange: {
          startDate: null,
          endDate: null
        }
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
