import moment from "moment";

export const columns = [
  {
    field: 'createdAt',
    headerName: 'Créé le',
    width: 180,
    valueGetter: (params) => `${moment(params.row.createdAt).format('DD/MM/YYYY,hh:mm')} `
  },

  {
    field: 'companyName',
    headerName: 'Nom société',
    width: 250
    //valueGetter: (params) => `${params.row.eirId || ""} `,
  },
  {
    field: 'taxId',
    headerName: 'Identifiant fiscal',
    width: 180
    //valueGetter: (params) => `${params.row.eirId || ""} `,
  },
  { field: 'pays', headerName: 'Pays', width: 150 },
  { field: 'address', headerName: 'Adresse', width: 500 },
  {
    field: 'yard',
    headerName: 'Actif(s)',
    width: 300,
    renderCell: (params) =>
      params.row.yard.map((yard) => (
        <div
          key={yard}
          style={{
            color: params.row?.actifVBSParcs?.includes(yard) ? '#006E00' : '#FF0000',
            fontWeight: params.row?.actifVBSParcs?.includes(yard) ? 'bold' : 'normal'
          }}>
          -{yard}
        </div>
      ))
  }
];
