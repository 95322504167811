import { useState, useEffect } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import NotificationItem from 'examples/Items/NotificationItem';
import LanguageIcon from '@mui/icons-material/Language';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu
} from 'examples/Navbars/DashboardNavbar/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';
import { withTranslation } from 'react-i18next';
import fr from 'assets/images/fr.png';
import en from 'assets/images/uk.jpg';
import usePrefLang from 'hooks/usePrefLanguage';
import i18next from 'i18next';
import { logout } from 'actions/auth';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { Box, Grid, Tooltip } from '@mui/material';
function DashboardNavbar({ absolute, light, isMini, t, logout, loggedUser }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openLangMenu, setLangMenu] = useState(false);
  // eslint-disable-next-line
  const [preferredLanguageCode, setPref] = usePrefLang('fr');

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // eslint-disable-next-line
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // eslint-disable-next-line
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLangMenu = (event) => setLangMenu(event.currentTarget);
  const handleCloseLangMenu = () => setLangMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}>
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );
  // Render the notifications menu
  const renderLangMenu = () => (
    <Menu
      anchorEl={openLangMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={Boolean(openLangMenu)}
      onClose={handleCloseLangMenu}
      sx={{ mt: 2 }}>
      <NotificationItem
        icon={<img src={en} style={{ width: '30px' }} alt="frenchlogo" />}
        title={t('english')}
        onClick={() => {
          setPref('en');
          i18next.changeLanguage('en');
          handleCloseLangMenu();
        }}
      />
      <NotificationItem
        icon={<img src={fr} style={{ width: '38px', marginLeft: '-5px' }} alt="uklogo" />}
        title={t('french')}
        onClick={() => {
          setPref('fr');
          i18next.changeLanguage('fr');
          handleCloseLangMenu();
        }}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    }
  });
  const signOut = () => {
    logout();
  };
  const companyName = JSON.parse(localStorage.getItem('user')).companyName;
  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}>
      {isMini ? null : (
        <MDBox>
          <Grid container spacing={2}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start'
                },
                alignItems: 'center'
              }}
              item
              xs={12}
              md={8}>
              <h4>{companyName}</h4>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box
                sx={{
                  textAlign: 'center',
                  display: miniSidenav ? '' : 'none'
                }}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}>
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? 'menu_open' : 'menu'}
                  </Icon>
                </IconButton>
              </Box>

              <MDBox color={light ? 'white' : 'inherit'}>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'space-between',
                      md: 'flex-end'
                    },
                    alignItems: 'center'
                  }}>
                  <Avatar>
                    {loggedUser.displayName
                      ? loggedUser.displayName.slice(0, 2).toUpperCase()
                      : loggedUser.displayName}
                  </Avatar>
                  <Tooltip title={i18next.language === 'fr' ? 'Langue' : 'Language'}>
                    <IconButton
                      sx={navbarIconButton}
                      size="medium"
                      onClick={handleLangMenu}
                      disableRipple>
                      <LanguageIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={i18next.language === 'fr' ? 'Déconnexion' : 'Logout'}>
                    <Link to="/authentication/sign-in" onClick={signOut}>
                      <IconButton sx={navbarIconButton} size="medium" disableRipple>
                        <ExitToAppIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Grid>

                {renderMenu()}
                {renderLangMenu()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool
};
const mapStateToProps = (state) => ({
  loggedUser: state.auth.loggedUser
});
export default connect(mapStateToProps, { logout })(withTranslation()(DashboardNavbar));
