import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// Data
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  FormHelperText,
  Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CustomDialog from 'components/CustomComponent/CustomDialog/CustomDialog';
import {
  getTruckerDetails,
  getParcSetup,
  addLicensePlate,
  addCDL,
  addTuckerInfo,
  deletePlate,
  deleteDriver
} from 'actions/vbs';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Truckers = ({
  getTruckerDetails,
  associatedDepot,
  managerMail,
  truckerDetails,
  t,
  addLicensePlate,
  addCDL,
  addTuckerInfo,
  deletePlate,
  deleteDriver
}) => {
  const [parcId, setParcId] = useState(localStorage.getItem('parcId'));
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleCDL, setIsVisibleCDL] = useState(false);
  const [license_plate, setLicensePlate] = useState('');
  const [driverInfo, setDriverInfo] = useState({ name: '', cdl: '' });
  const [cdlError, setCdlError] = useState(false);
  const [plateError, setPlateError] = useState(false);
  const [emptyPlateError, setEmptyPlateError] = useState(false);
  const [emptyCdlError, setEmptyCdlError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [stateSnackBarPlate, setStateSnackBarPlate] = useState({
    openPlate: false,
    verticalPlate: 'top',
    horizontalPlate: 'center',
    idPlate: ''
  });
  const { verticalPlate, horizontalPlate, openPlate, idPlate } = stateSnackBarPlate;

  const [stateSnackBarDriver, setStateSnackBarDriver] = useState({
    openDriver: false,
    verticalDriver: 'top',
    horizontalDriver: 'center',
    idDriver: '',
    nameDriver: ''
  });

  const { verticalDriver, horizontalDriver, openDriver, idDriver, nameDriver } =
    stateSnackBarDriver;

  useEffect(async () => {
    try {
      setLoadingData(true);
      await getTruckerDetails(localStorage.getItem('parcId'), managerMail);
      setLoadingData(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  /********Plate******* */
  /**Destruct snackbar object */

  /** Open SnackBar */
  const handleClickSnackBarPlate = (newState) => {
    setStateSnackBarPlate({ ...newState, openPlate: true });
  };
  /** Close SnackBar  */
  const handleCloseSnackBarPlate = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStateSnackBarPlate({ ...stateSnackBarPlate, openPlate: false });
  };
  /********Driver******* */

  /** Open SnackBar */
  const handleClickSnackBarDriver = (newState) => {
    setStateSnackBarDriver({ ...newState, openDriver: true });
  };
  /** Close SnackBar  */
  const handleCloseSnackBarDriver = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStateSnackBarDriver({ ...stateSnackBarDriver, openDriver: false });
  };

  const styles = {
    snackbarStyleViaContentProps: {
      backgroundColor: 'orange'
    },
    snackbarStyleViaNestedContent: {
      backgroundColor: 'lightgreen',
      color: 'black'
    }
  };
  /**Parc choice */
  const handleDepotChange = (e) => {
    setParcId(e.target.value);
    localStorage.setItem('parcId', e.target.value);
    getTruckerDetails(e.target.value, managerMail);
  };
  /** plate input */
  const handleInputChange = (e) => {
    const { trucks } = truckerDetails;
    setLicensePlate(e.target.value);
    if (trucks.includes(e.target.value.trim())) {
      setPlateError(true);
      e.target.blur();
    } else setPlateError(false);
    if (e.target.value.length > 0) {
      setEmptyPlateError(false);
    }
  };

  /** CDL and Name input */
  const handleInputChangeCdlAndName = (e) => {
    // const { cdlAndName } = truckerDetails;
    setDriverInfo({ ...driverInfo, [e.target.name]: e.target.value });
    if (
      truckerDetails &&
      truckerDetails.cdlAndName &&
      truckerDetails.cdlAndName.some((el) => el.cdl === e.target.value.trim())
    ) {
      setCdlError(true);
      e.target.blur();
    } else setCdlError(false);
    if (e.target.value.length > 0) {
      setEmptyPlateError(false);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setEmptyPlateError(false);
  };
  const handleCloseCDL = () => {
    setIsVisibleCDL(false);
    setEmptyCdlError(false);
    setEmptyNameError(false);
  };
  const submitLicensePlate = () => {
    const { companyName, trucks } = truckerDetails;
    if (license_plate === '') {
      setEmptyPlateError(true);
    }
    if (!plateError && license_plate !== '') {
      addLicensePlate(parcId, companyName, { trucks: [license_plate, ...trucks] });
      truckerDetails = { trucks: [...trucks, license_plate] };
      setLicensePlate('');
      setIsVisible(false);
    }
  };
  /**Delete a plate */
  const deleteSupplierPlate = (plate) => {
    const { companyName, trucks } = truckerDetails;
    deletePlate(parcId, companyName, plate);
  };
  /**Delete a driver */

  const deleteSupplierDriver = (driver) => {
    const { companyName } = truckerDetails;
    deleteDriver(parcId, companyName, driver);
  };

  /**Submit Driver Info */
  const submitDriverInfo = (e) => {
    e.preventDefault();

    if (driverInfo.cdl === '') {
      setEmptyCdlError(true);
    }
    if (driverInfo.name === '') {
      setEmptyNameError(true);
    }
    if (!cdlError && driverInfo.cdl !== '' && driverInfo.name !== '') {
      if (Array.isArray(truckerDetails.cdlAndName)) {
        addTuckerInfo(parcId, truckerDetails.companyName, {
          cdlAndName: [...truckerDetails.cdlAndName, { name: driverInfo.name, cdl: driverInfo.cdl }]
        });
      } else {
        addTuckerInfo(parcId, truckerDetails.companyName, {
          ...truckerDetails,
          cdlAndName: [{ name: driverInfo.name, cdl: driverInfo.cdl }]
        });
      }

      setDriverInfo({ name: '', cdl: '' });
      setIsVisibleCDL(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
          <Grid xs={12} md={6} pb={1} item>
            {parcId ? (
              <Button
                // variant="contained"
                onClick={() => setIsVisible(true)}
                style={{ backgroundColor: 'rgb(15,105,231)', color: 'white', marginTop: '2px' }}>
                {t('add_license_plate')}
              </Button>
            ) : null}

            {parcId ? (
              <Button
                variant="contained"
                onClick={() => setIsVisibleCDL(true)}
                style={{
                  backgroundColor: 'rgb(15,105,231)',
                  color: 'white',
                  marginLeft: '20px',
                  marginTop: '2px'
                }}>
                {t('add_driver_info')}
              </Button>
            ) : null}
          </Grid>
          <Grid xs={12} md={6} item></Grid>
        </Grid>
        {loadingData ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={70} />
          </Box>
        ) : null}
        <MDBox>
          <Grid container spacing={6} item>
            <Grid item xs={12}>
              {parcId ? (
                truckerDetails &&
                truckerDetails.companyName && (
                  <>
                    {/* parc Data */}
                    <MDBox style={{ fontSize: 14, marginBottom: '25px' }}>
                      <Card>
                        <Grid container style={{ padding: '5px' }}>
                          <Grid container style={{ padding: '5px' }}>
                            <Grid md={3} item></Grid>
                            <Grid xs={12} md={6} item>
                              <FormControl fullWidth>
                                <InputLabel>{t('depot')}</InputLabel>
                                <Select
                                  value={parcId}
                                  label="depot"
                                  name={t('depot')}
                                  style={{
                                    paddingBottom: '10px',
                                    textAlign: 'center',
                                    paddingTop: '10px',
                                    backgroundColor: 'rgb(240,242,245)'
                                  }}
                                  onChange={(e) => handleDepotChange(e)}>
                                  {associatedDepot &&
                                    associatedDepot.length > 0 &&
                                    associatedDepot.map((depot) => (
                                      <MenuItem value={depot} key={depot}>
                                        {depot}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid xs={12} container spacing={2} item>
                              <Grid xs={12} textAlign="center" md={4} item>
                                <strong>{t('companyName')} : </strong>
                                {truckerDetails.companyName}
                              </Grid>
                              <Grid xs={12} textAlign="center" md={4} item>
                                <strong> {t('country')} : </strong> {truckerDetails.pays}
                              </Grid>
                              <Grid xs={12} textAlign="center" md={4} item>
                                <strong> {t('address')} : </strong>
                                {truckerDetails.address}
                              </Grid>
                            </Grid>
                            <Grid xs={12} p={1} item container>
                              <Grid xs={12} textAlign="center" md={4} item>
                                <strong> ID Tax : </strong> {truckerDetails.taxId}
                              </Grid>
                              <Grid xs={12} textAlign="center" md={4} item>
                                <strong> Code : </strong>
                                {truckerDetails.code ? truckerDetails.code : 'NA'}
                              </Grid>
                            </Grid>

                            {truckerDetails.contacts.length > 0 ? (
                              // <Grid xs={12} item p={1} container>
                              <>
                                <strong>Contacts : </strong>
                                {truckerDetails.contacts.map((value) => (
                                  <Grid xs={12} item key={value} container>
                                    <Grid xs={12} md={4} item>
                                      <b style={{ color: '#008080' }}> {t('name')} : </b>{' '}
                                      {value.name}
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                      <b style={{ color: '#008080' }}> Email: </b> {value.email}
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                      <b style={{ color: '#008080' }}> {t('phoneNumber')} : </b>{' '}
                                      {value.tel ? value.tel.number : 'NA'}
                                    </Grid>
                                  </Grid>
                                ))}
                                {/* </Grid> */}
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Card>
                    </MDBox>

                    {/* Plate and driver data */}

                    <Card>
                      <Box
                        sx={{ flexGrow: 1, fontSize: 17, marginLeft: '100px' }}
                        justifyContent="center">
                        <Grid container spacing={2}>
                          {truckerDetails.trucks && truckerDetails.trucks.length > 0 ? (
                            <>
                              <Snackbar
                                ContentProps={{
                                  sx: { backgroundColor: 'rgb(210,210,210)' }
                                }}
                                anchorOrigin={{
                                  vertical: verticalPlate,
                                  horizontal: horizontalPlate
                                }}
                                open={openPlate}
                                onClose={handleCloseSnackBarPlate}
                                message={
                                  <span style={{ fontSize: '20px', color: 'black' }}>
                                    {t('Confirm deletion of the plate')}
                                    <strong style={{ color: 'red' }}>{idPlate}</strong>
                                  </span>
                                }
                                action={
                                  <React.Fragment>
                                    <Button
                                      style={{ fontSize: '20px', color: 'white' }}
                                      color="secondary"
                                      size="small"
                                      onClick={(e) => {
                                        handleCloseSnackBarPlate();
                                        deleteSupplierPlate(idPlate);
                                      }}>
                                      {t('Yes')}
                                    </Button>
                                    <Button
                                      style={{ fontSize: '20px', color: 'white' }}
                                      color="secondary"
                                      size="small"
                                      onClick={handleCloseSnackBarPlate}>
                                      {t('No')}
                                    </Button>
                                    <IconButton
                                      size="small"
                                      aria-label="close"
                                      color="inherit"
                                      onClick={handleCloseSnackBarPlate}>
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </React.Fragment>
                                }
                                key={`${verticalPlate}${horizontalPlate}`}
                              />
                              <Grid xs={12} item sm={5}>
                                <strong>{t('savedtruckerplates')} :</strong>
                                {truckerDetails.trucks.map((value, index) => (
                                  <>
                                    <Grid xs={12} item key={index} container alignItems="center">
                                      <Tooltip title={t('Delete')}>
                                        <DeleteIcon
                                          className="deleteButton"
                                          style={{ color: 'rgb(210,210,210)', marginRight: '2px' }}
                                          onClick={(e) => {
                                            handleClickSnackBarPlate({
                                              verticalPlate: 'top',
                                              horizontalPlate: 'center',
                                              idPlate: value
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                      <b style={{ color: '#008080' }}> {t('truckerplate')} :</b>{' '}
                                      {value}
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            </>
                          ) : null}

                          {truckerDetails?.cdlAndName && truckerDetails?.cdlAndName?.length > 0 ? (
                            <>
                              <Snackbar
                                ContentProps={{
                                  sx: { backgroundColor: 'rgb(210,210,210)' }
                                }}
                                anchorOrigin={{
                                  vertical: verticalDriver,
                                  horizontal: horizontalDriver
                                }}
                                open={openDriver}
                                onClose={handleCloseSnackBarDriver}
                                message={
                                  <span style={{ fontSize: '19px', color: 'black' }}>
                                    {t('Confirm deletion of the driver')}
                                    <strong style={{ color: 'red' }}> {nameDriver}</strong>
                                    {t('with CDL number')}
                                    <strong style={{ color: 'red' }}>{idDriver}</strong>
                                  </span>
                                }
                                action={
                                  <React.Fragment>
                                    <Button
                                      style={{ fontSize: '20px', color: 'white' }}
                                      color="secondary"
                                      size="small"
                                      onClick={(e) => {
                                        handleCloseSnackBarDriver();
                                        deleteSupplierDriver(idDriver);
                                      }}>
                                      {t('Yes')}
                                    </Button>
                                    <Button
                                      style={{ fontSize: '20px', color: 'white' }}
                                      color="secondary"
                                      size="small"
                                      onClick={handleCloseSnackBarDriver}>
                                      {t('No')}
                                    </Button>
                                    <IconButton
                                      size="small"
                                      aria-label="close"
                                      color="inherit"
                                      onClick={handleCloseSnackBarDriver}>
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </React.Fragment>
                                }
                                key={`${verticalDriver}${horizontalDriver}`}
                              />
                              <Grid xs={12} item sm={7}>
                                <strong>{t('savedtrukercdl')} :</strong>
                                {truckerDetails.cdlAndName.map((el, id) => (
                                  <Grid key={id} container alignItems="center">
                                    <Tooltip title={t('Delete')}>
                                      <DeleteIcon
                                        className="deleteButton"
                                        style={{ color: 'rgb(210,210,210)', marginRight: '2px' }}
                                        onClick={(e) => {
                                          handleClickSnackBarDriver({
                                            verticalDriver: 'top',
                                            horizontalDriver: 'center',
                                            idDriver: el.cdl,
                                            nameDriver: el.name
                                          });
                                        }}
                                      />
                                    </Tooltip>

                                    <Grid item xs={7}>
                                      <b style={{ color: '#008080' }}> {t('name')} :</b> {el.name}{' '}
                                    </Grid>

                                    <Grid
                                      item
                                      xs={3}
                                      style={{ display: 'flex', alignItems: 'center' }}>
                                      <b style={{ color: '#008080' }}> {t('cdl')} : </b> {el.cdl}
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    </Card>
                  </>
                )
              ) : (
                <Grid textAlign="center" item>
                  {!parcId && t('PleaseSelectYard')}
                </Grid>
              )}
            </Grid>
          </Grid>
        </MDBox>
        {/** Add License Plate */}
        <CustomDialog
          isVisible={isVisible}
          title={`${t('add_license_plate')} : ${parcId}`}
          paragraph={
            <>
              <br></br>
              <TextField
                id="outlined-basic"
                label={`${t('license_plate')}`}
                name="serialNumber"
                variant="outlined"
                value={license_plate}
                onChange={(e) => handleInputChange(e)}
                fullWidth
                error={plateError}
                inputProps={{ maxLength: 20 }}
              />
              {plateError ? (
                <FormHelperText style={{ color: '#ff271a' }}>
                  {t('Plaque camion existante')}
                </FormHelperText>
              ) : emptyPlateError ? (
                <FormHelperText style={{ color: '#ff271a' }}>
                  {t('Merci de renseigner une plaque camion')}
                </FormHelperText>
              ) : null}
            </>
          }
          handleClose={handleClose}
          handleSubmit={submitLicensePlate}
        />
        {/* Add driver Info */}
        <CustomDialog
          isVisible={isVisibleCDL}
          title={`${t('add_driver_info')} : ${parcId}`}
          paragraph={
            <>
              <br></br>
              <TextField
                id="outlined-basic"
                label={`${t('add_name')}`}
                variant="outlined"
                value={driverInfo.name}
                name="name"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleInputChangeCdlAndName(e)}
                fullWidth
                error={emptyNameError}
              />
              {emptyNameError ? (
                <FormHelperText style={{ color: '#ff271a' }}>
                  {t('Merci de renseigner le prénom et le nom du chauffeur')}
                </FormHelperText>
              ) : null}
              <br></br>
              <TextField
                id="outlined-basic"
                label={`${t('add_CDL')}`}
                variant="outlined"
                value={driverInfo.cdl}
                name="cdl"
                style={{ marginTop: '10px' }}
                inputProps={{ maxLength: 30 }} // max characters 30
                onChange={(e) => handleInputChangeCdlAndName(e)}
                fullWidth
                error={cdlError || emptyCdlError}
              />
              {cdlError ? (
                <FormHelperText style={{ color: '#ff271a' }}>{t('cdl existant')}</FormHelperText>
              ) : emptyCdlError ? (
                <FormHelperText style={{ color: '#ff271a' }}>
                  {t('Merci de renseigner cdl')}
                </FormHelperText>
              ) : null}
            </>
          }
          handleClose={handleCloseCDL}
          handleSubmit={submitDriverInfo}
        />

        <Footer />
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  truckerDetails: state.vbs.truckerDetails,
  associatedDepot: state.auth.loggedUser.associatedDepot,
  managerMail: state.auth.loggedUser.email
});

export default connect(mapStateToProps, {
  getTruckerDetails,
  getParcSetup,
  addLicensePlate,
  addCDL,
  addTuckerInfo,
  deletePlate,
  deleteDriver
})(withTranslation()(Truckers));
