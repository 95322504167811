// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

const Footer = ({ t, version }) => {
  const { size } = typography;
  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}>
        Shipzzer {version} &copy; {new Date().getFullYear()}, {t('All rights reserved Copyright')}{' '}
      </MDBox>
    </MDBox>
  );
};

const mapStateToProps = (state) => ({
  version: state.auth.version
});
export default connect(mapStateToProps, null)(withTranslation()(Footer));
