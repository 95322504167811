import { GET_VBS_YARDS } from "actions/types";
import { LOGOUT } from "actions/types";
import { ADD_TRANSPORTER } from "actions/types";
import { GET_TRANSPORTERS } from "actions/types";
import { GET_ALL_YARDS } from "actions/types";
import { GET_TRANSPORTERS_VBS, SET_TRANSPORTER_YARDS } from "actions/types";

const initialState = {
  allYards: [],
  transporters: [],
  vbsYards: [],
  allTransportersVbs: [],
  yardsPerTransporter: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_YARDS:
      return {
        ...state,
        allYards: payload,
      };
    case GET_TRANSPORTERS:
      return {
        ...state,
        transporters: payload,
      };
    case GET_VBS_YARDS:
      return {
        ...state,
        vbsYards: payload,
      };
    case GET_TRANSPORTERS_VBS:
      return {
        ...state,
        allTransportersVbs: payload,
      };
    case SET_TRANSPORTER_YARDS:
      return {
        ...state,
        yardsPerTransporter: payload,
      };
    case ADD_TRANSPORTER:
      return {
        ...state,
        transporters: state.transporters.map((trans) =>
          trans.companyName === payload.companyName ? { ...trans, yard: payload.yards } : trans
        ),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
