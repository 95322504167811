export const LOADING = 'LOADING';
export const LOGIN = 'LOGIN';
export const LOAD_USER = 'LOAD_USER';
export const LOGOUT = 'LOGOUT';
export const ISOCODES = 'ISOCODES';
export const GET_PARC_SETUP = 'GET_PARC_SETUP';
export const GET_TRUCKERS = 'GET_TRUCKERS';
export const GET_TRUCKERS_ERROR = 'GET_TRUCKERS_ERROR';
export const REINIT = 'REINIT';
export const ADD_PLATE = 'ADD_PLATE';
export const ADD_CDL = 'ADD_CDL';
export const ADD_CDLANDNAME = 'ADD_CDLANDNAME';

export const GET_EIR_HISTORY = 'GET_EIR_HISTORY';

export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const GET_ALL_APPOINTEMENT = 'GET_ALL_APPOINTEMENT';

export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS';
export const GET_PARC_COUNTER = 'GET_PARC_COUNTER';
export const GET_ACTIF_TRUCKERS_NUMBER = 'GET_ACTIF_TRUCKERS_NUMBER';

export const GET_ALL_YARDS = 'GET_ALL_YARDS';
export const GET_TRANSPORTERS = 'GET_TRANSPORTERS';
export const ADD_TRANSPORTER = 'ADD_TRANSPORTER';

export const GET_VBS_YARDS = 'GET_VBS_YARDS';

export const GET_TRANSPORTERS_VBS = 'GET_TRANSPORTERS_VBS';

export const SET_TRANSPORTER_YARDS = 'SET_TRANSPORTER_YARDS';

//export const SET_USER_DETAILS = "SET_USER_DETAILS"

export const CREATE_USER = 'CREATE_USER';
export const GET_USERS_PER_YARD = 'GET_USERS_PER_YARD';
export const GET_USERS_PER_COMPANYNAME = 'GET_USERS_PER_COMPANYNAME';

export const DISABLE_ENABLE_USER = 'DISABLE_ENABLE_USER';

export const EDIT_USER = 'EDIT_USER';

export const GET_DATE_RANGE = 'GET_DATE_RANGE';

export const GET_INFRASTRUCTURE_VERSION = 'GET_INFRASTRUCTURE_VERSION';
export const DELETE_PLATE = 'DELETE_PLATE';
export const DELETE_DRIVER = 'DELETE_DRIVER';
